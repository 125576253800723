import { Close, Plus } from "@kiwicom/orbit-components/icons";
import IconHolder from "../../../../src/documentation/03-components/08-visuals/icon/IconHolder";
import IconList from "../../../../src/components/IconList";
import IconColorsSnippet from "snippets/icon-colors.mdx";
import * as React from 'react';
export default {
  Close,
  Plus,
  IconHolder,
  IconList,
  IconColorsSnippet,
  React
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Slider`}</h1>
    <p>{`To implement Slider component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Slider from "@kiwicom/orbit-components/lib/Slider";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Slider defaultValue={5} onChange={value => doSomething(value)} />
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the Slider component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaLabel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string or string[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`aria-label`}</inlineCode>{` attribute or attributes for handles. See `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`functional specs`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaValueText`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Readable text alternative of current value. See `}<a parentName="td" {...{
              "href": "#accessibility"
            }}>{`accessibility`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`Slider`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`defaultValue`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#value"
            }}><inlineCode parentName="a">{`Value`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Initial value of the Slider when it mounts. See `}<a parentName="td" {...{
              "href": "#value"
            }}>{`value type`}</a>{` for advanced usage.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`histogramData`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Property for passing the histogram’s data. See `}<a parentName="td" {...{
              "href": "#histogram"
            }}>{`Histogram`}</a>{` for more info.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`histogramDescription`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text property where you should display the total count of displayed data. See `}<a parentName="td" {...{
              "href": "#histogram"
            }}>{`Histogram`}</a>{` for more info.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`histogramLoading`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` the Loading component will replace the Histogram. See `}<a parentName="td" {...{
              "href": "#histogram"
            }}>{`Histogram`}</a>{` for more info.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`histogramLoadingText`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The text of the Histogram when it’s loading. See `}<a parentName="td" {...{
              "href": "#histogram"
            }}>{`Histogram`}</a>{` for more info.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label of the Slider. Should communicate what is the purpose of it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxValue`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`100`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum value of the Slider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`minValue`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum value of the Slider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Value => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback for handling onChange event. See `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`functional specs`}</a>{` for advanced usage.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onChangeAfter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Value => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Calback for handling onChangeAfter event. See `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`functional specs`}</a>{` for advanced usage.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onChangeBefore`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Value => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback for handling onChangeBefore event. See `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`functional specs`}</a>{` for advanced usage.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`step`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Value that should be added or subtracted when Handle moves. The `}<inlineCode parentName="td">{`maxValue`}</inlineCode>{` and `}<inlineCode parentName="td">{`minValue`}</inlineCode>{` should be divisible by this number and it should be integer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`valueDescription`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Text property where you should display the selected value range.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Value`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` component supports usage with one handle and also with multiple handles.`}</p>
    <p>{`If you want to use `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` with range possibility, just simply pass array of numbers to the `}<inlineCode parentName="p">{`defaultValue`}</inlineCode>{` property, for instance `}<inlineCode parentName="p">{`[1, 12]`}</inlineCode>{`.
The exact same type will be then returned with all callbacks. e.g.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Slider
  defaultValue={[1, 12]}
  onChange={value => {
    console.log(value); // [X, Y]
  }}
/>
`}</code></pre>
    <h2>{`Histogram`}</h2>
    <ul>
      <li parentName="ul">{`If you need to use `}<inlineCode parentName="li">{`Slider`}</inlineCode>{` component together with `}<inlineCode parentName="li">{`Histogram`}</inlineCode>{`, use property `}<inlineCode parentName="li">{`histogramData`}</inlineCode>{` for that.`}</li>
      <li parentName="ul">{`You need pass the same amount of data that is possible to select by definition of `}<inlineCode parentName="li">{`minValue`}</inlineCode>{`, `}<inlineCode parentName="li">{`maxValue`}</inlineCode>{` and `}<inlineCode parentName="li">{`step`}</inlineCode>{` property. The total count of columns should be `}<inlineCode parentName="li">{`(maxValue - minValue + step) / step`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The Histogram won’t be visible on desktop devices until the user focuses one of the handles. On mobile devices is the Histogram always shown.`}</li>
      <li parentName="ul">{`By default, the `}<inlineCode parentName="li">{`histogramLoadingText`}</inlineCode>{` is null and only glyph of `}<inlineCode parentName="li">{`inlineLoader`}</inlineCode>{` will appear.`}</li>
      <li parentName="ul">{`With Histogram, it’s recommended to use also `}<inlineCode parentName="li">{`histogramDescription`}</inlineCode>{` property, where you should display the total count of selected data from the array. For it, you can use the `}<a parentName="li" {...{
          "href": "#calculatecountof"
        }}><inlineCode parentName="a">{`calculateCountOf`}</inlineCode></a>{` function.`}</li>
    </ul>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">{`When you use range type of the `}<inlineCode parentName="li">{`Slider`}</inlineCode>{` component, you should specify `}<inlineCode parentName="li">{`ariaLabel`}</inlineCode>{` property as array of labels. For instance: `}<inlineCode parentName="li">{`["First handle", "Second handle]`}</inlineCode>{`. If you use simple `}<inlineCode parentName="li">{`Slider`}</inlineCode>{`, just one string (not array) is enough.`}</li>
      <li parentName="ul">{`In every case of using the `}<inlineCode parentName="li">{`Slider`}</inlineCode>{` component on `}<strong parentName="li">{`mobile devices`}</strong>{`, the `}<inlineCode parentName="li">{`Slider`}</inlineCode>{` should be wrapped in the `}<strong parentName="li">{`Popover`}</strong>{`. For instance like this:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const MobileSlider = () => {
  const [value, setValue] = React.useState([1, 24]);
  return (
    <Popover
      content={
        <Slider defaultValue={[1, 24]} minValue={1} maxValue={24} onChange={val => setValue(val)} />
      }
    >
      <Tag selected={!!value}>Time of departure</Tag>
    </Popover>
  );
};
`}</code></pre>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`You should use `}<inlineCode parentName="li">{`ariaValueText`}</inlineCode>{` only for cases when the value cannot be accurately represented as a number. For instance, when you use the Slider for selection of time range, you should dynamically change the `}<inlineCode parentName="li">{`ariaValueText`}</inlineCode>{` to current selection e.g. `}<inlineCode parentName="li">{`00:00 to 13:00`}</inlineCode>{` every time when `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` triggers.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const SliderExample = () => {
  const [value, setValue] = React.useState(12);
  const ariaValueText = \`from midnight to \${value}\`;
  return (
    <Slider
      defaultValue={12}
      minValue={1}
      maxValue={24}
      onChange={val => setValue(val)}
      ariaValueText={ariaValueText}
    />
  );
};
`}</code></pre>
    <h2>{`calculateCountOf`}</h2>
    <p>{`Function `}<inlineCode parentName="p">{`calculateCountOf`}</inlineCode>{` will help you to count the total number of selected data and total number of all columns. You can then use these returned values for displaying the `}<inlineCode parentName="p">{`histogramDescription`}</inlineCode>{` property to the user, properly.`}</p>
    <p>{`For using it, you can use this reference:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import calculateCountOf from "@kiwicom/orbit-components/lib/Slider/utils/calculateCountOf";

const histogramData = [0, 10, 14, 40, 0, 11];
const value = [1, 3]; // can be just number also
const minValue = 1;
const [selectedCount, totalCount] = calculateCountOf(histogramData, value, minValue);

console.log(\`Showing \${selectedCount} of \${totalCount}\`); // Showing 24 of 75 flights
`}</code></pre>
    <h2>{`Data-test`}</h2>
    <p>{`There is a `}<inlineCode parentName="p">{`dataTest`}</inlineCode>{` prop for ability to test the component. There are also hardcoded `}<inlineCode parentName="p">{`data-test`}</inlineCode>{` attribute on handlers in format `}<inlineCode parentName="p">{`SliderHandle-\${index}`}</inlineCode>{` where index starts from `}<inlineCode parentName="p">{`0`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Use actionable text`}</h3>
    <p>{`It should be clear from the button text exactly what happens when the user interacts with it.
The labels should be actionable, such as “Add passenger” and “Book for (price)“.`}</p>
    <p>{`Avoid long explanations in the button text.
The text should be short and clear.
If additional explanation is needed, add it above the button as text.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "/kiwi-use/content/voice-and-tone/straightforward/#make-every-action-clear"
      }}>{`examples for how to make actions clear`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
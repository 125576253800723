import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Support progressive disclosure`}</h3>
    <p>{`While it makes sense to provide users with the information they need to make a good choice,
providing them with too much information at once actually makes it harder to decide.`}</p>
    <p>{`So while you might be tempted to stuff everything into labels and additional info,
you should try to use `}<a parentName="p" {...{
        "href": "/design-patterns/progressive-disclosure/"
      }}>{`progressive disclosure`}</a>{`.
This means using `}<a parentName="p" {...{
        "href": "/components/action/textlink/"
      }}>{`text links`}</a>{` and `}<a parentName="p" {...{
        "href": "/components/overlay/tooltip/"
      }}>{`tooltips`}</a>{`
to keep info hidden but close by.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
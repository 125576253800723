"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _getSizeToken = _interopRequireDefault(require("./getSizeToken"));
var _getPadding = _interopRequireDefault(require("./getPadding"));
var _consts = require("./consts");
const getCommonProps = ({
  width,
  size = _consts.SIZE_OPTIONS.NORMAL,
  theme,
  iconRight,
  contentAlign,
  contentWidth,
  fullWidth,
  centered,
  iconLeft,
  children
}) => {
  const onlyIcon = Boolean((iconLeft || iconRight) && !children);
  const hasCenteredContent = Boolean(onlyIcon || children && !(iconLeft || iconRight) || fullWidth && centered);
  return {
    ...(0, _getSizeToken.default)(size, theme),
    width,
    padding: (0, _getPadding.default)(onlyIcon, iconRight, iconLeft, size, theme),
    fontWeight: theme.orbit.fontWeightMedium,
    contentAlign: contentAlign || (onlyIcon || hasCenteredContent ? "center" : "space-between"),
    contentWidth: contentWidth || (fullWidth && centered ? undefined : "100%")
  };
};
var _default = exports.default = getCommonProps;
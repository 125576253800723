import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Maintain visual hierarchy`}</h3>
    <p>
  {`${props.component.replace(/^\w/, c => c.toUpperCase())}s`} are open to any content you want to
  put into them. If you're putting a heading into a {props.component}, we recommend using the{" "}
  {props.component} title, which was designed for this purpose. Or at least use the same style of
  heading to keep the overall visual hierarchy.
    </p>
    <p>
  Using different styles can create conflicting visual messages, especially if you're using multiple{" "}
  {`${props.component}s`} on the same screen.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
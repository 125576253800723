"use strict";

exports.__esModule = true;
exports.default = void 0;
const createRel = ({
  rel,
  href,
  external
}) => {
  const relValues = rel ? rel.split(" ") : [];
  // add noopener  whenever external
  if (external && href) {
    if (!relValues.includes("noopener")) {
      relValues.push("noopener");
    }
  }
  return relValues.length > 0 ? relValues.join(" ") : undefined;
};
var _default = exports.default = createRel;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useClickOutside`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`useClickOutside`}</inlineCode>{` executes a certain action whenever there is a click outside of a given component.`}</p>
    <p>{`To implement the `}<inlineCode parentName="p">{`useClickOutside`}</inlineCode>{` hook in your component, add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useClickOutside from "@kiwicom/orbit-components/lib/hooks/useClickOutside";
`}</code></pre>
    <p>{`Then you can use it in your functional component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const App = () => {
  const elementRef = React.useRef<HTMLDivElement | null>(null);
  const handleClickOutside = ev => console.log(\`The following event was detected: \${ev}\`);

  useClickOutside(elementRef, handleClickOutside);

  return (
    <div ref={elementRef}>
      <span>Any click outside the parent div will trigger a log of the event.</span>
    </div>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Offer similar content to everyone`}</h3>
    <p>{`Users approach their interactions with your content similarly no matter their device.
They’re looking for information or to accomplish a task.
If a user can’t find that information or feature on your mobile site or app,
they assume you don’t have it.`}</p>
    <p>{`Make sure your content is available everywhere.
Change its form so it fits best in the space available
and fits user expectations for the device.
But keep it present.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
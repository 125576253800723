import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useMediaQuery`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`useMediaQuery`}</inlineCode>{` hook lets you use media queries in your functional component and differentiate its render for different viewports.`}</p>
    <p>{`To implement the useMediaQuery hook into your project, add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useMediaQuery from "@kiwicom/orbit-components/lib/hooks/useMediaQuery";
`}</code></pre>
    <p>{`Then you can use it in your functional component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Component = () => {
  const { isDesktop } = useMediaQuery();
  return <div>{isDesktop ? "We are on desktop" : "We are on mobile or tablet"}</div>;
};
`}</code></pre>
    <p>{`Because Orbit aims to be mobile-first, you should consider your base return without conditional rendering to be the mobile one. Therefore, the `}<inlineCode parentName="p">{`useMediaQuery`}</inlineCode>{` hook returns only an object of booleans according to the possible breakpoints you might know from normal CSS media queries or dynamic media properties on components.`}</p>
    <h2>{`Return`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`useMediaQuery`}</inlineCode>{` hook returns an object with boolean values depending on which viewport the user is currently on or what system preferences have the device.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isMediumMobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if viewport equals or is more than `}<inlineCode parentName="td">{`414px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isLargeMobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if viewport equals or is more than `}<inlineCode parentName="td">{`576px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isTablet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if viewport equals or is more than `}<inlineCode parentName="td">{`768px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isDesktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if viewport equals or is more than `}<inlineCode parentName="td">{`992px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isLargeDesktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if viewport equals or is more than `}<inlineCode parentName="td">{`1200px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`prefersReducedMotion`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode>{` if device uses reduced motion. Please mind that this media query selector doesn’t work on IE10+ and therefore it will be always `}<inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.backgroundColorClasses = void 0;
var _mediaQuery = require("../../utils/mediaQuery");
const backgroundColorClasses = exports.backgroundColorClasses = {
  blueLight: "bg-blue-light",
  blueLightHover: "bg-blue-light-hover",
  blueLightActive: "bg-blue-light-active",
  blueNormal: "bg-blue-normal",
  blueNormalHover: "bg-blue-normal-hover",
  bundleBasic: "bg-bundle-basic",
  bundleMedium: "bg-bundle-medium",
  blueNormalActive: "bg-blue-normal-active",
  blueDark: "bg-blue-dark",
  blueDarkHover: "bg-blue-dark-hover",
  blueDarkActive: "bg-blue-dark-active",
  blueDarker: "bg-blue-darker",
  cloudLight: "bg-cloud-light",
  cloudLightHover: "bg-cloud-light-hover",
  cloudLightActive: "bg-cloud-light-active",
  cloudNormal: "bg-cloud-normal",
  cloudNormalHover: "bg-cloud-normal-hover",
  cloudNormalActive: "bg-cloud-normal-active",
  cloudDark: "bg-cloud-dark",
  cloudDarkHover: "bg-cloud-dark-hover",
  cloudDarkActive: "bg-cloud-dark-active",
  greenLight: "bg-green-light",
  greenLightHover: "bg-green-light-hover",
  greenLightActive: "bg-green-light-active",
  greenNormal: "bg-green-normal",
  greenNormalHover: "bg-green-normal-hover",
  greenNormalActive: "bg-green-normal-active",
  greenDark: "bg-green-dark",
  greenDarkHover: "bg-green-dark-hover",
  greenDarkActive: "bg-green-dark-active",
  greenDarker: "bg-green-darker",
  inkDark: "bg-ink-dark",
  inkDarkHover: "bg-ink-dark-hover",
  inkDarkActive: "bg-ink-dark-active",
  inkLight: "bg-ink-light",
  inkLightHover: "bg-ink-light-hover",
  inkLightActive: "bg-ink-light-active",
  inkNormal: "bg-ink-normal",
  inkNormalHover: "bg-ink-normal-hover",
  inkNormalActive: "bg-ink-normal-active",
  orangeLight: "bg-orange-light",
  orangeLightHover: "bg-orange-light-hover",
  orangeLightActive: "bg-orange-light-active",
  orangeNormal: "bg-orange-normal",
  orangeNormalHover: "bg-orange-normal-hover",
  orangeNormalActive: "bg-orange-normal-active",
  orangeDark: "bg-orange-dark",
  orangeDarkHover: "bg-orange-dark-hover",
  orangeDarkActive: "bg-orange-dark-active",
  orangeDarker: "bg-orange-darker",
  productLight: "bg-product-light",
  productLightHover: "bg-product-light-hover",
  productLightActive: "bg-product-light-active",
  productNormal: "bg-product-normal",
  productNormalHover: "bg-product-normal-hover",
  productNormalActive: "bg-product-normal-active",
  productDark: "bg-product-dark",
  productDarkHover: "bg-product-dark-hover",
  productDarkActive: "bg-product-dark-active",
  productDarker: "bg-product-darker",
  redLight: "bg-red-light",
  redLightHover: "bg-red-light-hover",
  redLightActive: "bg-red-light-active",
  redNormal: "bg-red-normal",
  redNormalHover: "bg-red-normal-hover",
  redNormalActive: "bg-red-normal-active",
  redDark: "bg-red-dark",
  redDarkHover: "bg-red-dark-hover",
  redDarkActive: "bg-red-dark-active",
  redDarker: "bg-red-darker",
  socialFacebook: "bg-social-facebook",
  socialFacebookHover: "bg-social-facebook-hover",
  socialFacebookActive: "bg-social-facebook-active",
  white: "bg-white-normal",
  whiteNormal: "bg-white-normal",
  whiteHover: "bg-white-normal-hover",
  whiteActive: "bg-white-normal-active",
  [_mediaQuery.QUERIES.LARGEDESKTOP]: {
    blueLight: "ld:bg-blue-light",
    blueLightHover: "ld:bg-blue-light-hover",
    blueLightActive: "ld:bg-blue-light-active",
    blueNormal: "ld:bg-blue-normal",
    bundleBasic: "ld:bg-bundle-basic",
    bundleMedium: "ld:bg-bundle-medium",
    blueNormalHover: "ld:bg-blue-normal-hover",
    blueNormalActive: "ld:bg-blue-normal-active",
    blueDark: "ld:bg-blue-dark",
    blueDarkHover: "ld:bg-blue-dark-hover",
    blueDarkActive: "ld:bg-blue-dark-active",
    blueDarker: "ld:bg-blue-darker",
    cloudLight: "ld:bg-cloud-light",
    cloudLightHover: "ld:bg-cloud-light-hover",
    cloudLightActive: "ld:bg-cloud-light-active",
    cloudNormal: "ld:bg-cloud-normal",
    cloudNormalHover: "ld:bg-cloud-normal-hover",
    cloudNormalActive: "ld:bg-cloud-normal-active",
    cloudDark: "ld:bg-cloud-dark",
    cloudDarkHover: "ld:bg-cloud-dark-hover",
    cloudDarkActive: "ld:bg-cloud-dark-active",
    greenLight: "ld:bg-green-light",
    greenLightHover: "ld:bg-green-light-hover",
    greenLightActive: "ld:bg-green-light-active",
    greenNormal: "ld:bg-green-normal",
    greenNormalHover: "ld:bg-green-normal-hover",
    greenNormalActive: "ld:bg-green-normal-active",
    greenDark: "ld:bg-green-dark",
    greenDarkHover: "ld:bg-green-dark-hover",
    greenDarkActive: "ld:bg-green-dark-active",
    greenDarker: "ld:bg-green-darker",
    inkDark: "ld:bg-ink-dark",
    inkDarkHover: "ld:bg-ink-dark-hover",
    inkDarkActive: "ld:bg-ink-dark-active",
    inkLight: "ld:bg-ink-light",
    inkLightHover: "ld:bg-ink-light-hover",
    inkLightActive: "ld:bg-ink-light-active",
    inkNormal: "ld:bg-ink-normal",
    inkNormalHover: "ld:bg-ink-normal-hover",
    inkNormalActive: "ld:bg-ink-normal-active",
    orangeLight: "ld:bg-orange-light",
    orangeLightHover: "ld:bg-orange-light-hover",
    orangeLightActive: "ld:bg-orange-light-active",
    orangeNormal: "ld:bg-orange-normal",
    orangeNormalHover: "ld:bg-orange-normal-hover",
    orangeNormalActive: "ld:bg-orange-normal-active",
    orangeDark: "ld:bg-orange-dark",
    orangeDarkHover: "ld:bg-orange-dark-hover",
    orangeDarkActive: "ld:bg-orange-dark-active",
    orangeDarker: "ld:bg-orange-darker",
    productLight: "ld:bg-product-light",
    productLightHover: "ld:bg-product-light-hover",
    productLightActive: "ld:bg-product-light-active",
    productNormal: "ld:bg-product-normal",
    productNormalHover: "ld:bg-product-normal-hover",
    productNormalActive: "ld:bg-product-normal-active",
    productDark: "ld:bg-product-dark",
    productDarkHover: "ld:bg-product-dark-hover",
    productDarkActive: "ld:bg-product-dark-active",
    productDarker: "ld:bg-product-darker",
    redLight: "ld:bg-red-light",
    redLightHover: "ld:bg-red-light-hover",
    redLightActive: "ld:bg-red-light-active",
    redNormal: "ld:bg-red-normal",
    redNormalHover: "ld:bg-red-normal-hover",
    redNormalActive: "ld:bg-red-normal-active",
    redDark: "ld:bg-red-dark",
    redDarkHover: "ld:bg-red-dark-hover",
    redDarkActive: "ld:bg-red-dark-active",
    redDarker: "ld:bg-red-darker",
    socialFacebook: "ld:bg-social-facebook",
    socialFacebookHover: "ld:bg-social-facebook-hover",
    socialFacebookActive: "ld:bg-social-facebook-active",
    white: "ld:bg-white-normal",
    whiteNormal: "ld:bg-white-normal",
    whiteHover: "ld:bg-white-normal-hover",
    whiteActive: "ld:bg-white-normal-active"
  },
  [_mediaQuery.QUERIES.DESKTOP]: {
    blueLight: "de:bg-blue-light",
    blueLightHover: "de:bg-blue-light-hover",
    blueLightActive: "de:bg-blue-light-active",
    blueNormal: "de:bg-blue-normal",
    blueNormalHover: "de:bg-blue-normal-hover",
    blueNormalActive: "de:bg-blue-normal-active",
    blueDark: "de:bg-blue-dark",
    bundleBasic: "de:bg-bundle-basic",
    bundleMedium: "de:bg-bundle-medium",
    blueDarkHover: "de:bg-blue-dark-hover",
    blueDarkActive: "de:bg-blue-dark-active",
    blueDarker: "de:bg-blue-darker",
    cloudLight: "de:bg-cloud-light",
    cloudLightHover: "de:bg-cloud-light-hover",
    cloudLightActive: "de:bg-cloud-light-active",
    cloudNormal: "de:bg-cloud-normal",
    cloudNormalHover: "de:bg-cloud-normal-hover",
    cloudNormalActive: "de:bg-cloud-normal-active",
    cloudDark: "de:bg-cloud-dark",
    cloudDarkHover: "de:bg-cloud-dark-hover",
    cloudDarkActive: "de:bg-cloud-dark-active",
    greenLight: "de:bg-green-light",
    greenLightHover: "de:bg-green-light-hover",
    greenLightActive: "de:bg-green-light-active",
    greenNormal: "de:bg-green-normal",
    greenNormalHover: "de:bg-green-normal-hover",
    greenNormalActive: "de:bg-green-normal-active",
    greenDark: "de:bg-green-dark",
    greenDarkHover: "de:bg-green-dark-hover",
    greenDarkActive: "de:bg-green-dark-active",
    greenDarker: "de:bg-green-darker",
    inkDark: "de:bg-ink-dark",
    inkDarkHover: "de:bg-ink-dark-hover",
    inkDarkActive: "de:bg-ink-dark-active",
    inkLight: "de:bg-ink-light",
    inkLightHover: "de:bg-ink-light-hover",
    inkLightActive: "de:bg-ink-light-active",
    inkNormal: "de:bg-ink-normal",
    inkNormalHover: "de:bg-ink-normal-hover",
    inkNormalActive: "de:bg-ink-normal-active",
    orangeLight: "de:bg-orange-light",
    orangeLightHover: "de:bg-orange-light-hover",
    orangeLightActive: "de:bg-orange-light-active",
    orangeNormal: "de:bg-orange-normal",
    orangeNormalHover: "de:bg-orange-normal-hover",
    orangeNormalActive: "de:bg-orange-normal-active",
    orangeDark: "de:bg-orange-dark",
    orangeDarkHover: "de:bg-orange-dark-hover",
    orangeDarkActive: "de:bg-orange-dark-active",
    orangeDarker: "de:bg-orange-darker",
    productLight: "de:bg-product-light",
    productLightHover: "de:bg-product-light-hover",
    productLightActive: "de:bg-product-light-active",
    productNormal: "de:bg-product-normal",
    productNormalHover: "de:bg-product-normal-hover",
    productNormalActive: "de:bg-product-normal-active",
    productDark: "de:bg-product-dark",
    productDarkHover: "de:bg-product-dark-hover",
    productDarkActive: "de:bg-product-dark-active",
    productDarker: "de:bg-product-darker",
    redLight: "de:bg-red-light",
    redLightHover: "de:bg-red-light-hover",
    redLightActive: "de:bg-red-light-active",
    redNormal: "de:bg-red-normal",
    redNormalHover: "de:bg-red-normal-hover",
    redNormalActive: "de:bg-red-normal-active",
    redDark: "de:bg-red-dark",
    redDarkHover: "de:bg-red-dark-hover",
    redDarkActive: "de:bg-red-dark-active",
    redDarker: "de:bg-red-darker",
    socialFacebook: "de:bg-social-facebook",
    socialFacebookHover: "de:bg-social-facebook-hover",
    socialFacebookActive: "de:bg-social-facebook-active",
    white: "de:bg-white-normal",
    whiteNormal: "de:bg-white-normal",
    whiteHover: "de:bg-white-normal-hover",
    whiteActive: "de:bg-white-normal-active"
  },
  [_mediaQuery.QUERIES.TABLET]: {
    blueLight: "tb:bg-blue-light",
    blueLightHover: "tb:bg-blue-light-hover",
    blueLightActive: "tb:bg-blue-light-active",
    blueNormal: "tb:bg-blue-normal",
    bundleBasic: "tb:bg-bundle-basic",
    bundleMedium: "tb:bg-bundle-medium",
    blueNormalHover: "tb:bg-blue-normal-hover",
    blueNormalActive: "tb:bg-blue-normal-active",
    blueDark: "tb:bg-blue-dark",
    blueDarkHover: "tb:bg-blue-dark-hover",
    blueDarkActive: "tb:bg-blue-dark-active",
    blueDarker: "tb:bg-blue-darker",
    cloudLight: "tb:bg-cloud-light",
    cloudLightHover: "tb:bg-cloud-light-hover",
    cloudLightActive: "tb:bg-cloud-light-active",
    cloudNormal: "tb:bg-cloud-normal",
    cloudNormalHover: "tb:bg-cloud-normal-hover",
    cloudNormalActive: "tb:bg-cloud-normal-active",
    cloudDark: "tb:bg-cloud-dark",
    cloudDarkHover: "tb:bg-cloud-dark-hover",
    cloudDarkActive: "tb:bg-cloud-dark-active",
    greenLight: "tb:bg-green-light",
    greenLightHover: "tb:bg-green-light-hover",
    greenLightActive: "tb:bg-green-light-active",
    greenNormal: "tb:bg-green-normal",
    greenNormalHover: "tb:bg-green-normal-hover",
    greenNormalActive: "tb:bg-green-normal-active",
    greenDark: "tb:bg-green-dark",
    greenDarkHover: "tb:bg-green-dark-hover",
    greenDarkActive: "tb:bg-green-dark-active",
    greenDarker: "tb:bg-green-darker",
    inkDark: "tb:bg-ink-dark",
    inkDarkHover: "tb:bg-ink-dark-hover",
    inkDarkActive: "tb:bg-ink-dark-active",
    inkLight: "tb:bg-ink-light",
    inkLightHover: "tb:bg-ink-light-hover",
    inkLightActive: "tb:bg-ink-light-active",
    inkNormal: "tb:bg-ink-normal",
    inkNormalHover: "tb:bg-ink-normal-hover",
    inkNormalActive: "tb:bg-ink-normal-active",
    orangeLight: "tb:bg-orange-light",
    orangeLightHover: "tb:bg-orange-light-hover",
    orangeLightActive: "tb:bg-orange-light-active",
    orangeNormal: "tb:bg-orange-normal",
    orangeNormalHover: "tb:bg-orange-normal-hover",
    orangeNormalActive: "tb:bg-orange-normal-active",
    orangeDark: "tb:bg-orange-dark",
    orangeDarkHover: "tb:bg-orange-dark-hover",
    orangeDarkActive: "tb:bg-orange-dark-active",
    orangeDarker: "tb:bg-orange-darker",
    productLight: "tb:bg-product-light",
    productLightHover: "tb:bg-product-light-hover",
    productLightActive: "tb:bg-product-light-active",
    productNormal: "tb:bg-product-normal",
    productNormalHover: "tb:bg-product-normal-hover",
    productNormalActive: "tb:bg-product-normal-active",
    productDark: "tb:bg-product-dark",
    productDarkHover: "tb:bg-product-dark-hover",
    productDarkActive: "tb:bg-product-dark-active",
    productDarker: "tb:bg-product-darker",
    redLight: "tb:bg-red-light",
    redLightHover: "tb:bg-red-light-hover",
    redLightActive: "tb:bg-red-light-active",
    redNormal: "tb:bg-red-normal",
    redNormalHover: "tb:bg-red-normal-hover",
    redNormalActive: "tb:bg-red-normal-active",
    redDark: "tb:bg-red-dark",
    redDarkHover: "tb:bg-red-dark-hover",
    redDarkActive: "tb:bg-red-dark-active",
    redDarker: "tb:bg-red-darker",
    socialFacebook: "tb:bg-social-facebook",
    socialFacebookHover: "tb:bg-social-facebook-hover",
    socialFacebookActive: "tb:bg-social-facebook-active",
    white: "tb:bg-white-normal",
    whiteNormal: "tb:bg-white-normal",
    whiteHover: "tb:bg-white-normal-hover",
    whiteActive: "tb:bg-white-normal-active"
  },
  [_mediaQuery.QUERIES.LARGEMOBILE]: {
    blueLight: "lm:bg-blue-light",
    blueLightHover: "lm:bg-blue-light-hover",
    blueLightActive: "lm:bg-blue-light-active",
    bundleBasic: "lm:bg-bundle-basic",
    bundleMedium: "lm:bg-bundle-medium",
    blueNormal: "lm:bg-blue-normal",
    blueNormalHover: "lm:bg-blue-normal-hover",
    blueNormalActive: "lm:bg-blue-normal-active",
    blueDark: "lm:bg-blue-dark",
    blueDarkHover: "lm:bg-blue-dark-hover",
    blueDarkActive: "lm:bg-blue-dark-active",
    blueDarker: "lm:bg-blue-darker",
    cloudLight: "lm:bg-cloud-light",
    cloudLightHover: "lm:bg-cloud-light-hover",
    cloudLightActive: "lm:bg-cloud-light-active",
    cloudNormal: "lm:bg-cloud-normal",
    cloudNormalHover: "lm:bg-cloud-normal-hover",
    cloudNormalActive: "lm:bg-cloud-normal-active",
    cloudDark: "lm:bg-cloud-dark",
    cloudDarkHover: "lm:bg-cloud-dark-hover",
    cloudDarkActive: "lm:bg-cloud-dark-active",
    greenLight: "lm:bg-green-light",
    greenLightHover: "lm:bg-green-light-hover",
    greenLightActive: "lm:bg-green-light-active",
    greenNormal: "lm:bg-green-normal",
    greenNormalHover: "lm:bg-green-normal-hover",
    greenNormalActive: "lm:bg-green-normal-active",
    greenDark: "lm:bg-green-dark",
    greenDarkHover: "lm:bg-green-dark-hover",
    greenDarkActive: "lm:bg-green-dark-active",
    greenDarker: "lm:bg-green-darker",
    inkDark: "lm:bg-ink-dark",
    inkDarkHover: "lm:bg-ink-dark-hover",
    inkDarkActive: "lm:bg-ink-dark-active",
    inkLight: "lm:bg-ink-light",
    inkLightHover: "lm:bg-ink-light-hover",
    inkLightActive: "lm:bg-ink-light-active",
    inkNormal: "lm:bg-ink-normal",
    inkNormalHover: "lm:bg-ink-normal-hover",
    inkNormalActive: "lm:bg-ink-normal-active",
    orangeLight: "lm:bg-orange-light",
    orangeLightHover: "lm:bg-orange-light-hover",
    orangeLightActive: "lm:bg-orange-light-active",
    orangeNormal: "lm:bg-orange-normal",
    orangeNormalHover: "lm:bg-orange-normal-hover",
    orangeNormalActive: "lm:bg-orange-normal-active",
    orangeDark: "lm:bg-orange-dark",
    orangeDarkHover: "lm:bg-orange-dark-hover",
    orangeDarkActive: "lm:bg-orange-dark-active",
    orangeDarker: "lm:bg-orange-darker",
    productLight: "lm:bg-product-light",
    productLightHover: "lm:bg-product-light-hover",
    productLightActive: "lm:bg-product-light-active",
    productNormal: "lm:bg-product-normal",
    productNormalHover: "lm:bg-product-normal-hover",
    productNormalActive: "lm:bg-product-normal-active",
    productDark: "lm:bg-product-dark",
    productDarkHover: "lm:bg-product-dark-hover",
    productDarkActive: "lm:bg-product-dark-active",
    productDarker: "lm:bg-product-darker",
    redLight: "lm:bg-red-light",
    redLightHover: "lm:bg-red-light-hover",
    redLightActive: "lm:bg-red-light-active",
    redNormal: "lm:bg-red-normal",
    redNormalHover: "lm:bg-red-normal-hover",
    redNormalActive: "lm:bg-red-normal-active",
    redDark: "lm:bg-red-dark",
    redDarkHover: "lm:bg-red-dark-hover",
    redDarkActive: "lm:bg-red-dark-active",
    redDarker: "lm:bg-red-darker",
    socialFacebook: "lm:bg-social-facebook",
    socialFacebookHover: "lm:bg-social-facebook-hover",
    socialFacebookActive: "lm:bg-social-facebook-active",
    white: "lm:bg-white-normal",
    whiteNormal: "lm:bg-white-normal",
    whiteHover: "lm:bg-white-normal-hover",
    whiteActive: "lm:bg-white-normal-active"
  },
  [_mediaQuery.QUERIES.MEDIUMMOBILE]: {
    blueLight: "mm:bg-blue-light",
    blueLightHover: "mm:bg-blue-light-hover",
    blueLightActive: "mm:bg-blue-light-active",
    blueNormal: "mm:bg-blue-normal",
    bundleBasic: "mm:bg-bundle-basic",
    bundleMedium: "mm:bg-bundle-medium",
    blueNormalHover: "mm:bg-blue-normal-hover",
    blueNormalActive: "mm:bg-blue-normal-active",
    blueDark: "mm:bg-blue-dark",
    blueDarkHover: "mm:bg-blue-dark-hover",
    blueDarkActive: "mm:bg-blue-dark-active",
    blueDarker: "mm:bg-blue-darker",
    cloudLight: "mm:bg-cloud-light",
    cloudLightHover: "mm:bg-cloud-light-hover",
    cloudLightActive: "mm:bg-cloud-light-active",
    cloudNormal: "mm:bg-cloud-normal",
    cloudNormalHover: "mm:bg-cloud-normal-hover",
    cloudNormalActive: "mm:bg-cloud-normal-active",
    cloudDark: "mm:bg-cloud-dark",
    cloudDarkHover: "mm:bg-cloud-dark-hover",
    cloudDarkActive: "mm:bg-cloud-dark-active",
    greenLight: "mm:bg-green-light",
    greenLightHover: "mm:bg-green-light-hover",
    greenLightActive: "mm:bg-green-light-active",
    greenNormal: "mm:bg-green-normal",
    greenNormalHover: "mm:bg-green-normal-hover",
    greenNormalActive: "mm:bg-green-normal-active",
    greenDark: "mm:bg-green-dark",
    greenDarkHover: "mm:bg-green-dark-hover",
    greenDarkActive: "mm:bg-green-dark-active",
    greenDarker: "mm:bg-green-darker",
    inkDark: "mm:bg-ink-dark",
    inkDarkHover: "mm:bg-ink-dark-hover",
    inkDarkActive: "mm:bg-ink-dark-active",
    inkLight: "mm:bg-ink-light",
    inkLightHover: "mm:bg-ink-light-hover",
    inkLightActive: "mm:bg-ink-light-active",
    inkNormal: "mm:bg-ink-normal",
    inkNormalHover: "mm:bg-ink-normal-hover",
    inkNormalActive: "mm:bg-ink-normal-active",
    orangeLight: "mm:bg-orange-light",
    orangeLightHover: "mm:bg-orange-light-hover",
    orangeLightActive: "mm:bg-orange-light-active",
    orangeNormal: "mm:bg-orange-normal",
    orangeNormalHover: "mm:bg-orange-normal-hover",
    orangeNormalActive: "mm:bg-orange-normal-active",
    orangeDark: "mm:bg-orange-dark",
    orangeDarkHover: "mm:bg-orange-dark-hover",
    orangeDarkActive: "mm:bg-orange-dark-active",
    orangeDarker: "mm:bg-orange-darker",
    productLight: "mm:bg-product-light",
    productLightHover: "mm:bg-product-light-hover",
    productLightActive: "mm:bg-product-light-active",
    productNormal: "mm:bg-product-normal",
    productNormalHover: "mm:bg-product-normal-hover",
    productNormalActive: "mm:bg-product-normal-active",
    productDark: "mm:bg-product-dark",
    productDarkHover: "mm:bg-product-dark-hover",
    productDarkActive: "mm:bg-product-dark-active",
    productDarker: "mm:bg-product-darker",
    redLight: "mm:bg-red-light",
    redLightHover: "mm:bg-red-light-hover",
    redLightActive: "mm:bg-red-light-active",
    redNormal: "mm:bg-red-normal",
    redNormalHover: "mm:bg-red-normal-hover",
    redNormalActive: "mm:bg-red-normal-active",
    redDark: "mm:bg-red-dark",
    redDarkHover: "mm:bg-red-dark-hover",
    redDarkActive: "mm:bg-red-dark-active",
    redDarker: "mm:bg-red-darker",
    socialFacebook: "mm:bg-social-facebook",
    socialFacebookHover: "mm:bg-social-facebook-hover",
    socialFacebookActive: "mm:bg-social-facebook-active",
    white: "mm:bg-white-normal",
    whiteNormal: "mm:bg-white-normal",
    whiteHover: "mm:bg-white-normal-hover",
    whiteActive: "mm:bg-white-normal-active"
  }
};
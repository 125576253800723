import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const FancyLink = makeShortcode("FancyLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Bug reporting on GitHub`}</h2>
    <p>{`If you find any bugs in our components,
report them on GitHub and we’ll fix them as soon as possible.
It’s the highest priority to have Orbit working as expected.`}</p>
    <FancyLink title="Report bug" href="https://github.com/kiwicom/orbit/issues" icon="github" mdxType="FancyLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Orbit tailwind preset`}</h1>
    <p>{`This package contains the Orbit Tailwind preset.`}</p>
    <h2>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn add -D @kiwicom/orbit-tailwind-preset
`}</code></pre>
    <p>{`or with npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save-dev @kiwicom/orbit-tailwind-preset
`}</code></pre>
    <p>{`The default exported preset (`}<inlineCode parentName="p">{`orbitComponentsPreset`}</inlineCode>{`) includes all of the Orbit’s foundation styles (typography, colors, spacing, etc…) and all the needed configuration for component-specific classes used internally.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`orbitComponentsPreset`}</inlineCode>{` function accepts an optional object with one property, `}<inlineCode parentName="p">{`disablePreflight`}</inlineCode>{`, which is `}<inlineCode parentName="p">{`false`}</inlineCode>{` by default. When set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, it disables the normalization of the browser’s default styles. We recommend leaving it enabled, as it avoids the need to reset the browser’s default styles manually. Please note that disabling it can also cause unexpected visual regressions, so disable it at your own risk. The normalization is done by the `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/docs/preflight"
      }}>{`Tailwind CSS Preflight`}</a>{` plugin.`}</p>
    <h2>{`Configuration`}</h2>
    <p>{`In your `}<inlineCode parentName="p">{`tailwind.config.js`}</inlineCode>{` file (or equivalent), add the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const path = require("node:path");
const orbitComponentsPreset = require("@kiwicom/orbit-tailwind-preset");
// the following ensures the correct path is found (especially within monorepos)
const orbitComponentsPath = require
  .resolve("@kiwicom/orbit-components")
  .replace("/lib/index.js", "");

module.exports = {
  content: [
    // ...
    path.join(orbitComponentsPath, "**", "*.js"),
  ],
  // If you need, customize the base font family, e.g. with the System UI font stack (https://github.com/system-fonts/modern-font-stacks#system-ui)
  theme: {
    extend: {
      fontFamily: {
        base: "system-ui, sans-serif",
      },
    },
  },
  presets: [
    orbitComponentsPreset({
      disablePreflight: false, // default value
    }),
  ],
};
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`content`}</inlineCode>{` property is required for Tailwind to `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/docs/content-configuration"
      }}>{`know which files to scan for classes`}</a>{`. It should include the path to all your source files that use Tailwind classes. The path to the `}<inlineCode parentName="p">{`@kiwicom/orbit-components`}</inlineCode>{` package is required for the component-specific classes to be scanned and built into the final CSS. (The `}<inlineCode parentName="p">{`require.resolve`}</inlineCode>{` ensures it works inside of monorepos too).`}</p>
    <p>{`The `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property allows you to override some specific values of the default theme. In particular, if you need to customize the font family, you can change the `}<inlineCode parentName="p">{`base`}</inlineCode>{` font family like in the example above.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`presets`}</inlineCode>{` property is required for Tailwind to `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/docs/presets"
      }}>{`know which presets to use`}</a>{`. It should include the `}<inlineCode parentName="p">{`orbitComponentsPreset`}</inlineCode>{` function, which returns the Orbit Tailwind preset.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`As an example, the classes applied below become available right away. They apply, respectively, the CSS properties `}<inlineCode parentName="p">{`color`}</inlineCode>{` with the value of the palette token `}<inlineCode parentName="p">{`blueNormal`}</inlineCode>{` and `}<inlineCode parentName="p">{`padding`}</inlineCode>{` with the value of the spacing token `}<inlineCode parentName="p">{`small`}</inlineCode>{`. Both tokens are available on the `}<inlineCode parentName="p">{`@kiwicom/orbit-design-tokens`}</inlineCode>{` package.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class="text-blue-normal p-sm">...</p>
`}</code></pre>
    <p>{`Notice that other component-specific classes will also be available. However, they are not expected to be used outside of Orbit’s internal development. They are not documented and Orbit is not responsible for possible breaking changes that might occur by its usage in other projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Use labels`}</h3>
    <p>{`Labels serve to clearly present what’s expected.
They’re especially important for `}<a parentName="p" {...{
        "href": "/foundation/accessibility/"
      }}>{`people who don’t see other visual cues`}</a>{`.
But they also help everyone know exactly what to enter.`}</p>
    <p>{`For the label, use short descriptive phrases,
ideally nouns that make the request clear.
See our `}<a parentName="p" {...{
        "href": "/kiwi-use/content/glossary/#form-labels"
      }}>{`patterns for form labels`}</a>{` for some examples.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
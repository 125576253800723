"use strict";

exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
const useErrorTooltip = ({
  onFocus,
  hasTooltip = true
}) => {
  const [tooltipShown, setTooltipShown] = (0, _react.useState)(false);
  const [tooltipShownHover, setTooltipShownHover] = (0, _react.useState)(false);
  const labelRef = (0, _react.useRef)(null);
  const iconRef = (0, _react.useRef)(null);
  const handleFocus = (0, _react.useCallback)(ev => {
    if (onFocus) onFocus(ev);
    if (hasTooltip) setTooltipShown(true);
  }, [onFocus, hasTooltip]);
  return {
    tooltipShown,
    tooltipShownHover,
    setTooltipShown,
    setTooltipShownHover,
    labelRef,
    iconRef,
    handleFocus
  };
};
var _default = exports.default = useErrorTooltip;
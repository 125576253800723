"use strict";

exports.__esModule = true;
exports.sizeStyles = exports.paddingRightIconStyles = exports.paddingNoIconsStyles = exports.paddingLeftIconStyles = exports.paddingBothIconsStyles = exports.iconOnlyStyles = void 0;
const sizeStyles = exports.sizeStyles = {
  small: "h-form-box-small text-small",
  normal: "h-form-box-normal text-normal",
  large: "h-form-box-large text-large"
};
const paddingNoIconsStyles = exports.paddingNoIconsStyles = {
  small: "px-button-padding-sm",
  normal: "px-button-padding-md",
  large: "px-button-padding-lg"
};
const paddingLeftIconStyles = exports.paddingLeftIconStyles = {
  small: "ps-button-padding-xs pe-button-padding-sm",
  normal: "ps-button-padding-sm pe-button-padding-md",
  large: "ps-button-padding-md pe-button-padding-lg"
};
const paddingRightIconStyles = exports.paddingRightIconStyles = {
  small: "ps-button-padding-sm pe-button-padding-xs",
  normal: "ps-button-padding-md pe-button-padding-sm",
  large: "ps-button-padding-lg pe-button-padding-md"
};
const paddingBothIconsStyles = exports.paddingBothIconsStyles = {
  small: "px-button-padding-xs",
  normal: "px-button-padding-sm",
  large: "px-button-padding-md"
};
const iconOnlyStyles = exports.iconOnlyStyles = {
  small: "w-form-box-small",
  normal: "w-form-box-normal",
  large: "w-form-box-large"
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Right to left languages`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`orbit-components`}</inlineCode>{` package supports right to left languages.`}</p>
    <p>{`If you need to enable `}<inlineCode parentName="p">{`rtl`}</inlineCode>{` inside `}<inlineCode parentName="p">{`orbit-components`}</inlineCode>{`, it can be done with `}<inlineCode parentName="p">{`OrbitProvider`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { OrbitProvider, defaultTheme } from "@kiwicom/orbit-components";

<OrbitProvider useId={React.useId} theme={{ ...defaultTheme, rtl: true }}>
  <App />
</OrbitProvider>;
`}</code></pre>
    <p>{`In addition, you also need to set the `}<inlineCode parentName="p">{`dir`}</inlineCode>{` property on the `}<inlineCode parentName="p">{`html`}</inlineCode>{` element to have value `}<inlineCode parentName="p">{`rtl`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html dir="rtl">
  ...
</html>
`}</code></pre>
    <h2>{`Rendering Bidirectional Text in RTL Mode`}</h2>
    <p>{`When rendering bidirectional text (mixing both LTR and RTL characters) in RTL mode, it’s important to understand how the browser handles the rendering.`}</p>
    <h3>{`Fully RTL Text`}</h3>
    <p>{`If the text is fully RTL, such as numbers and Hebrew characters, it will be rendered properly.`}</p>
    <h3>{`Bidirectional Text`}</h3>
    <p>{`However, when using bidirectional text, such as “2 of 6”, the rendering may not be as expected. The browser considers it as RTL due to the `}<inlineCode parentName="p">{`dir="rtl"`}</inlineCode>{` attribute and starts rendering from right to left.`}</p>
    <p>{`The browser begins with “2”, which can be both LTR and RTL. When it encounters the “of 6” part, which is considered LTR, it results in the rendering of “of 6 2”.`}</p>
    <h3>{`Handling Bidirectional Text`}</h3>
    <p>{`To handle bidirectional text correctly:`}</p>
    <ol>
      <li parentName="ol">{`Use fully RTL text to ensure proper rendering.`}</li>
      <li parentName="ol">{`If using bidirectional text is necessary, wrap the LTR part with an element that has `}<inlineCode parentName="li">{`dir="ltr"`}</inlineCode>{` to enforce the correct rendering order.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div dir="rtl">שלום <span dir="ltr">world</span></div>
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Alternatively, if you don’t know the direction of the text, use the `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/bdi"
        }}><inlineCode parentName="a">{`bdi`}</inlineCode></a>{` element to wrap the unknown part.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div dir="rtl">שלום <bdi>world</bdi></div>
`}</code></pre>
    <p>{`For more information, see the official `}<a parentName="p" {...{
        "href": "https://www.w3.org/International/articles/inline-bidi-markup/"
      }}>{`W3 article`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
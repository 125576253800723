import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Truncate`}</h1>
    <p>{`To implement Truncate component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Truncate from "@kiwicom/orbit-components/lib/Truncate";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Truncate>
  Proin mattis lacinia justo. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam. Class
  aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Duis ante
  orci, molestie vitae vehicula venenatis, tincidunt ac pede.
</Truncate>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the Truncate component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional property for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content that should be truncated. See `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`functional specs`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`maxWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`100%`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional property when you need to explicitly setup `}<inlineCode parentName="td">{`max-width`}</inlineCode>{` of the component`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Truncate component renders only one, single line of truncated content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When you need to use combination of `}<inlineCode parentName="p">{`Truncate`}</inlineCode>{` and `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component, pass the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` as children, e.g. like this:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Truncate from "@kiwicom/orbit-components/lib/Truncate";
import Text from "@kiwicom/orbit-components/lib/Text";

const TruncatedText = ({ children }) => (
  <Truncate>
    <Text type="secondary">Text to truncate</Text>
  </Truncate>
);
`}</code></pre>
    <ul>
      <li parentName="ul">{`It’s also possible to use this component inside `}<inlineCode parentName="li">{`Stack`}</inlineCode>{`. By default, it will inherit the width of it with use of `}<inlineCode parentName="li">{`max-width: 100%`}</inlineCode>{`. If you need to stack two truncates or some combination differently, use `}<inlineCode parentName="li">{`maxWidth`}</inlineCode>{` property for that, e.g. like this:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Truncate from "@kiwicom/orbit-components/lib/Truncate";
import Stack from "@kiwicom/orbit-components/lib/Stack";

const TruncatedStacking = () => (
  <Stack direction="row">
    <Truncate maxWidth="20%">Text to truncate</Truncate>
    // second Truncate will take 80 % of space, by default
    <Truncate>Another text to truncate</Truncate>
  </Stack>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`OrbitProvider`}</h1>
    <p>{`orbit-components has theming support via our own `}<inlineCode parentName="p">{`<OrbitProvider>`}</inlineCode>{` which adds you the possibility to add your own theme.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import OrbitProvider from "@kiwicom/orbit-components/lib/OrbitProvider";
`}</code></pre>
    <p>{`After adding import please wrap your application into `}<inlineCode parentName="p">{`OrbitProvider`}</inlineCode>{` and you can provide your own `}<a parentName="p" {...{
        "href": "https://github.com/kiwicom/orbit/blob/master/.github/theming.md"
      }}><inlineCode parentName="a">{`theme`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<OrbitProvider useId={React.useId} theme={ownTheme}>
  <App />
</OrbitProvider>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the OrbitProvider component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Your app`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`theme`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`[Object]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`See `}<a parentName="td" {...{
              "href": "https://github.com/kiwicom/orbit/blob/master/.github/theming.md"
            }}><inlineCode parentName="a">{`theming`}</inlineCode></a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`useId`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`[Object]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If using React 18 or above, use `}<inlineCode parentName="td">{`React.useId`}</inlineCode>{`. If not, use `}<inlineCode parentName="td">{`useRandomId`}</inlineCode>{` from `}<a parentName="td" {...{
              "href": "https://www.npmjs.com/package/react-uid"
            }}><inlineCode parentName="a">{`react-uid`}</inlineCode></a>{`.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
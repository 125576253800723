"use strict";

exports.__esModule = true;
exports.rtlSpacing = exports.right = exports.left = void 0;
const leftToRight = (left, right) => ({
  theme
}) => theme.rtl ? right : left;
const rtlSpacing = value => ({
  theme
}) => {
  if (!theme.rtl) return value;
  const parts = value.split(" ").filter(part => !Number.isNaN(parseFloat(part)) && part);
  return parts.length === 4 ? [parts[0], parts[3], parts[2], parts[1]].join(" ") : value;
};

/**
 * @deprecated This function is deprecated. Use `start` properties instead.
 */
exports.rtlSpacing = rtlSpacing;
const left = exports.left = leftToRight("left", "right");

/**
 * @deprecated This function is deprecated. Use `end` properties instead.
 */
const right = exports.right = leftToRight("right", "left");
function separateGroupedAndUngrouped(options) {
  const {
    groups,
    ungrouped
  } = options.reduce((acc, option) => {
    const {
      group
    } = option;
    if (group) {
      if (!acc.groups[group]) {
        acc.groups[group] = [];
      }
      acc.groups[group].push(option);
    } else {
      acc.ungrouped.push(option);
    }
    return acc;
  }, {
    groups: {},
    ungrouped: []
  });
  const grouped = Object.values(groups);
  return {
    grouped,
    ungrouped
  };
}
export function groupOptions(options, showAll, prevSelected) {
  const {
    grouped,
    ungrouped
  } = separateGroupedAndUngrouped(options);
  if (prevSelected) {
    grouped.unshift([prevSelected]);
  }
  const flattenedGroups = grouped.reduce((acc, group) => [...acc, ...group], []);
  const flattened = showAll ? [...flattenedGroups, ...options] : [...flattenedGroups, ...ungrouped];
  return {
    groups: grouped,
    all: options,
    flattened
  };
}
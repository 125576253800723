"use strict";

exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
// https://usehooks-typescript.com/react-hook/use-is-mounted
function useIsMounted() {
  const isMounted = (0, _react.useRef)(false);
  (0, _react.useEffect)(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (0, _react.useCallback)(() => isMounted.current, []);
}
var _default = exports.default = useIsMounted;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _consts = require("../../../primitives/ButtonPrimitive/common/consts");
var _getAlertButtonTypeToken = _interopRequireDefault(require("./getAlertButtonTypeToken"));
var _consts2 = require("../consts");
var _getAlertButtonBoxShadow = _interopRequireDefault(require("./getAlertButtonBoxShadow"));
const getAlertButtonStyles = ({
  disabled,
  theme,
  type
}) => {
  const wrappedBoxShadow = state => (0, _getAlertButtonBoxShadow.default)(state, disabled, theme, type);
  const wrappedTypeToken = name => (0, _getAlertButtonTypeToken.default)(name, type, theme);
  const boxShadow = {
    boxShadow: wrappedBoxShadow(_consts.BUTTON_STATES.DEFAULT),
    boxShadowHover: wrappedBoxShadow(_consts.BUTTON_STATES.HOVER),
    boxShadowActive: wrappedBoxShadow(_consts.BUTTON_STATES.ACTIVE),
    boxShadowFocus: wrappedBoxShadow(_consts.BUTTON_STATES.FOCUS)
  };
  return {
    background: wrappedTypeToken(_consts2.TOKENS.backgroundButton),
    backgroundHover: wrappedTypeToken(_consts2.TOKENS.backgroundButtonHover),
    backgroundActive: wrappedTypeToken(_consts2.TOKENS.backgroundButtonActive),
    backgroundFocus: null,
    foreground: wrappedTypeToken(_consts2.TOKENS.colorTextButton),
    foregroundHover: wrappedTypeToken(_consts2.TOKENS.colorTextButtonHover),
    foregroundActive: wrappedTypeToken(_consts2.TOKENS.colorTextButtonActive),
    ...boxShadow
  };
};
var _default = exports.default = getAlertButtonStyles;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Use freedom wisely`}</h3>
    <p>
  With great freedom comes great responsibility. Primitive components offer many more options for
  styling. Use these options wisely and make sure your {`${props.component}s`} are still{" "}
  <a href="/foundation/accessibility/#ensure-proper-color-contrast" aria-label="color-contrast">
    accessible to everyone
  </a>
  .
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
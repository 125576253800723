import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Use error and help messages`}</h3>
    <p>{`For more complicated fields, sometimes labels aren’t enough.
You want to include any necessary information as clear as possible
to help users complete the fields.`}</p>
    <p>{`Use help messages to guide users before they enter anything
and clear calm error messages when there’s a problem with what they entered.`}</p>
    <p>{`Remember that such messages are likely to invoke negative feelings,
so be positive and focused on solutions to any problems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _padding = require("../../common/tailwind/padding");
const getPaddingClass = (padding, viewport) => {
  if (!padding) return null;
  if (typeof padding === "object") {
    const {
      top,
      bottom,
      left,
      right
    } = padding;
    return [top != null && (viewport ? _padding.paddingTopClasses[viewport][top] : _padding.paddingTopClasses[top]), bottom != null && (viewport ? _padding.paddingBottomClasses[viewport][bottom] : _padding.paddingBottomClasses[bottom]), left != null && (viewport ? _padding.paddingLeftClasses[viewport][left] : _padding.paddingLeftClasses[left]), right != null && (viewport ? _padding.paddingRightClasses[viewport][right] : _padding.paddingRightClasses[right])];
  }
  return viewport ? _padding.paddingClasses[viewport][padding] : _padding.paddingClasses[padding];
};
var _default = exports.default = getPaddingClass;
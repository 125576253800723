"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _consts = require("../consts");
var _getAlertButtonTypeToken = _interopRequireDefault(require("./getAlertButtonTypeToken"));
const getAlertButtonIconForeground = ({
  theme,
  type
}) => ({
  foreground: (0, _getAlertButtonTypeToken.default)(_consts.TOKENS.colorTextButton, type, theme)
});
var _default = exports.default = getAlertButtonIconForeground;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Warning = makeShortcode("Warning");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useLockScrolling`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`useLockScrolling`}</inlineCode>{` hook is useful when you want to lock scrolling to a given element, for example when a modal is open you want only its content to be scrollable, not the entire page (i.e. the “content in the background”).`}</p>
    <Warning mdxType="Warning">
      <p>{`Locking scrolling is a global functionality – there is only one `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` element, so you should use `}<inlineCode parentName="p">{`useLockScrolling`}</inlineCode>{` for all your (page) lock scrolling needs. It’s keeping track of how many components are using it at the moment to know when to actually unlock scrolling, so if your application is using this hook alongside other implementations of locking scrolling, you might run into bugs where scrolling is not locked when it should be, or much worse, locked when it shouldn’t be!`}</p>
    </Warning>
    <p>{`To implement the `}<inlineCode parentName="p">{`useLockScrolling`}</inlineCode>{` hook in your component, add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useLockScrolling from "@kiwicom/orbit-components/lib/hooks/useLockScrolling";
`}</code></pre>
    <p>{`Then you can use it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Component = (props: Props) => {
  const scrollableElementRef = (useRef < HTMLElement) | (null > null);

  // locks the scrolling only to ScrollableContainer
  useLockScrolling(scollableElementRef);

  return <ScrollableContainer ref={scrollableElementRef}>Hello world!</ScrollableContainer>;
};
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all parameters available to the `}<inlineCode parentName="p">{`useLockScrolling`}</inlineCode>{` hook.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`{ current: HTMLElement \\| null }`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Reference object of the scrollable container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`lock`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether to lock scrolling or not. This is useful for example if locking scrolling depends on a breakpoint.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dependencies`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`any[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Dependencies that affect the value of `}<inlineCode parentName="td">{`ref.current`}</inlineCode>{`, in case it’s not always assigned to the same HTML element.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Background color and borders`}</h3>
    <p>{`See why `}<a parentName="p" {...{
        "href": "/components/input/inputfield/#background-color-and-borders"
      }}>{`background and borders for fields`}</a>{`
differ between app and website versions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
export function throttle(fn, time) {
  let lastTime = 0;
  return (...args) => {
    const now = new Date();
    if (+now - lastTime >= time) {
      fn(...args);
      // @ts-expect-error TODO
      lastTime = now;
    }
  };
}
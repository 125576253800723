import React from "react";

export default function PuzzleIcon(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 140 140"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(9.18649,0,0,9.18649,-77.1153,-81.7918)">
        <path d="M17.05,20.379C16.676,20.004 16.466,19.496 16.466,18.967C16.466,17.869 17.369,16.967 18.466,16.967C19.286,16.967 20.027,17.471 20.327,18.234C20.402,18.425 20.587,18.551 20.792,18.551C20.925,18.551 21.052,18.499 21.146,18.405L22.707,16.843C23.095,16.455 23.095,15.817 22.707,15.429L21.107,13.829C21.979,13.596 22.59,12.8 22.59,11.897C22.59,10.8 21.687,9.897 20.59,9.897C19.687,9.897 18.891,10.508 18.658,11.38L17.058,9.78C16.67,9.392 16.032,9.392 15.644,9.78L14.195,11.229C14.101,11.323 14.047,11.451 14.047,11.584C14.047,11.752 14.132,11.909 14.272,12.001C14.384,12.075 14.488,12.16 14.583,12.255C14.943,12.628 15.144,13.126 15.144,13.644C15.144,14.742 14.242,15.644 13.144,15.644C12.626,15.644 12.128,15.443 11.755,15.083C11.66,14.988 11.575,14.884 11.501,14.772C11.409,14.632 11.252,14.547 11.084,14.547C10.951,14.547 10.823,14.601 10.729,14.695L9.272,16.136C8.884,16.524 8.884,17.162 9.272,17.55L14.929,23.207C15.317,23.595 15.955,23.595 16.343,23.207L17.905,21.646C17.999,21.552 18.052,21.425 18.052,21.292C18.052,21.086 17.925,20.901 17.734,20.826C17.477,20.726 17.245,20.574 17.05,20.379Z" />
      </g>
    </svg>
  );
}

"use strict";

exports.__esModule = true;
exports.TYPE_OPTIONS = exports.TOKENS = exports.CLOSE_BUTTON_DATA_TEST = void 0;
const TYPE_OPTIONS = exports.TYPE_OPTIONS = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  CRITICAL: "critical"
};
const TOKENS = exports.TOKENS = {
  backgroundAlert: "backgroundAlert",
  colorIconAlert: "colorIconAlert",
  colorTextLinkAlertHover: "colorTextLinkAlertHover",
  colorTextLinkAlertFocus: "colorTextLinkAlertFocus",
  colorBorderAlert: "colorBorderAlert",
  colorAccentBorder: "colorAccentBorder"
};
const CLOSE_BUTTON_DATA_TEST = exports.CLOSE_BUTTON_DATA_TEST = "AlertCloseButton";
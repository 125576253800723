"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
var _useTheme = _interopRequireDefault(require("../useTheme"));
var _useIsMounted = _interopRequireDefault(require("../useIsMounted"));
var _lockScrolling = require("./lock-scrolling");
const useLockScrolling = (ref, lock = true, dependencies = []) => {
  const {
    lockScrolling: themeLockScrolling = true,
    lockScrollingBarGap = true
  } = (0, _useTheme.default)();
  const isMounted = (0, _useIsMounted.default)();
  (0, _react.useEffect)(() => {
    const el = ref.current;
    if (el) {
      if (lock && themeLockScrolling) {
        (0, _lockScrolling.disableBodyScroll)(el, {
          reserveScrollBarGap: lockScrollingBarGap
        });
      }
      if (!lock || !themeLockScrolling) {
        (0, _lockScrolling.enableBodyScroll)(el);
      }
    }
    return () => {
      // disabling this because we're interested in precisely that changed value
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const didRefChange = el !== ref.current;
      if (el && (!isMounted() || didRefChange)) {
        (0, _lockScrolling.enableBodyScroll)(el);
      }
    };
  }, [ref, lock, themeLockScrolling, lockScrollingBarGap, isMounted, ...dependencies]);
};
var _default = exports.default = useLockScrolling;
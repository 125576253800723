import { useRandomId as useRandomIdContext } from "../../OrbitProvider/RandomId/Provider";
export const useRandomId = () => {
  const useId = useRandomIdContext();
  return useId();
};
export const useRandomIdSeed = () => {
  const useId = useRandomIdContext();
  const prefix = useId();
  return seed => `${prefix}-${seed}`;
};
export default useRandomId;
"use strict";

exports.__esModule = true;
exports.TYPE_OPTIONS = void 0;
const TYPE_OPTIONS = exports.TYPE_OPTIONS = {
  NEUTRAL: "neutral",
  INFO_SUBTLE: "infoSubtle",
  SUCCESS_SUBTLE: "successSubtle",
  WARNING_SUBTLE: "warningSubtle",
  CRITICAL_SUBTLE: "criticalSubtle",
  DARK: "dark",
  WHITE: "white",
  INFO: "info",
  CRITICAL: "critical",
  SUCCESS: "success",
  WARNING: "warning",
  BUNDLE_BASIC: "bundleBasic",
  BUNDLE_MEDIUM: "bundleMedium",
  BUNDLE_TOP: "bundleTop"
};
import getSizeToken from "./getSizeToken";
import getPadding from "./getPadding";
import { SIZE_OPTIONS } from "./consts";
const getCommonProps = ({
  width,
  size = SIZE_OPTIONS.NORMAL,
  theme,
  iconRight,
  contentAlign,
  contentWidth,
  fullWidth,
  centered,
  iconLeft,
  children
}) => {
  const onlyIcon = Boolean((iconLeft || iconRight) && !children);
  const hasCenteredContent = Boolean(onlyIcon || children && !(iconLeft || iconRight) || fullWidth && centered);
  return {
    ...getSizeToken(size, theme),
    width,
    padding: getPadding(onlyIcon, iconRight, iconLeft, size, theme),
    fontWeight: theme.orbit.fontWeightMedium,
    contentAlign: contentAlign || (onlyIcon || hasCenteredContent ? "center" : "space-between"),
    contentWidth: contentWidth || (fullWidth && centered ? undefined : "100%")
  };
};
export default getCommonProps;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Present info nonvisually`}</h3>
    <p>{`Illustrations can add a lot to your designs.
So you need to make sure everything necessary is presented to
`}<a parentName="p" {...{
        "href": "/foundation/accessibility/"
      }}>{`people who don’t see the illustration`}</a>{` as alternative text.`}</p>
    <p>{`Meaningful alternative text includes the `}<strong parentName="p">{`context`}</strong>{` and `}<strong parentName="p">{`function`}</strong>{` of the illustration.
Make sure everything you want users to know to continue in the flow is available to them.`}</p>
    <p>{`You can include alternative text through means like the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute.
Or you can present necessary alternative text next to the illustration
as `}<a parentName="p" {...{
        "href": "/components/text/text/"
      }}>{`text`}</a>{` or `}<a parentName="p" {...{
        "href": "/components/text/heading/"
      }}>{`headings`}</a>{`,
in which case you can set the `}<inlineCode parentName="p">{`alt`}</inlineCode>{` attribute to an empty string.`}</p>
    <p>{`If an illustration is only for decoration,
leave the alternative text `}<strong parentName="p">{`blank`}</strong>{`.
Providing a description just gives users extra information that doesn’t help them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _margin = require("../../common/tailwind/margin");
const getMarginClass = (margin, viewport) => {
  if (!margin) return null;
  if (typeof margin === "object") {
    const {
      top,
      bottom,
      left,
      right
    } = margin;
    return [top != null && (viewport ? _margin.marginTopClasses[viewport][top] : _margin.marginTopClasses[top]), bottom != null && (viewport ? _margin.marginBottomClasses[viewport][bottom] : _margin.marginBottomClasses[bottom]), left != null && (viewport ? _margin.marginLeftClasses[viewport][left] : _margin.marginLeftClasses[left]), right != null && (viewport ? _margin.marginRightClasses[viewport][right] : _margin.marginRightClasses[right])];
  }
  return viewport ? _margin.marginClasses[viewport][margin] : _margin.marginClasses[margin];
};
var _default = exports.default = getMarginClass;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import defaultTheme from "@kiwicom/orbit-components/lib/defaultTheme";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can set two things about illustrations.
The first is the illustration itself,
which you can choose from the `}<a parentName="p" {...{
        "href": "#available-illustrations"
      }}>{`complete list of illustrations`}</a>{`.`}</p>
    <p>{`The second is the size.
We have five different sizes to offer, which are based around the maximum height of the illustration.`}</p>
    <ul>
  <li>Extra small ({defaultTheme.orbit.heightIllustrationSmall})</li>
  <li>Small (120 px)</li>
  <li>Medium ({defaultTheme.orbit.heightIllustrationMedium})</li>
  <li>Large (280 px)</li>
  <li>Display (460 px)</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Include placeholder examples`}</h3>
    <p>{`When you have additional information or helpful examples,
include placeholder text to help users along.`}</p>
    <p>{`Remember that placeholder text is visually less important, low in contrast,
and disappears once users enter anything.
So do `}<strong parentName="p">{`not`}</strong>{` include anything `}<strong parentName="p">{`necessary`}</strong>{` to complete the field.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-changelog-mdx": () => import("./../../../src/pages/changelog.mdx" /* webpackChunkName: "component---src-pages-changelog-mdx" */),
  "component---src-pages-component-status-mdx": () => import("./../../../src/pages/component-status.mdx" /* webpackChunkName: "component---src-pages-component-status-mdx" */),
  "component---src-pages-examples-example-id-tsx": () => import("./../../../src/pages/examples/{Example.id}.tsx" /* webpackChunkName: "component---src-pages-examples-example-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-examples-mdx": () => import("./../../../src/pages/mdx-examples.mdx" /* webpackChunkName: "component---src-pages-mdx-examples-mdx" */),
  "component---src-pages-playground-full-tsx": () => import("./../../../src/pages/playground-full.tsx" /* webpackChunkName: "component---src-pages-playground-full-tsx" */),
  "component---src-pages-playground-tsx": () => import("./../../../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-themer-index-tsx": () => import("./../../../src/pages/themer/index.tsx" /* webpackChunkName: "component---src-pages-themer-index-tsx" */),
  "component---src-templates-doc-tsx": () => import("./../../../src/templates/Doc.tsx" /* webpackChunkName: "component---src-templates-doc-tsx" */),
  "component---src-templates-overview-tsx": () => import("./../../../src/templates/Overview.tsx" /* webpackChunkName: "component---src-templates-overview-tsx" */)
}


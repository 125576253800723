import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>Follow {props.component} guidelines</h3>
    <p>
  {props.component.replace(/^\w/, c => c.toUpperCase())} primitives are still{" "}
  {`${props.component}s`}. So make sure to follow all the{" "}
  <a href={`/components/${props.section}/${props.component}/`} aria-label="primitives">
    guidelines for {props.component} components
  </a>
  .
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`SkipLink`}</h1>
    <p>{`To implement SkipLink component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import SkipLink from "@kiwicom/orbit-components/lib/SkipLink";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SkipLink
  links={[
    {
      href: "#terms",
      name: "Go to terms and conditions",
    },
    {
      name: "Reguest refund",
      onClick: handler,
    },
  ]}
/>
`}</code></pre>
    <p>{`Screen reader will read the content in the following order:`}</p>
    <ul>
      <li parentName="ul">{`content of the link`}</li>
      <li parentName="ul">{`aria-label of nav`}</li>
      <li parentName="ul">{`navigation`}</li>
    </ul>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all types of props available in the SkipLink component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`buttonLabel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Description for screen readers.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`links`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#links"
            }}><inlineCode parentName="a">{`links[]`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array specifying links to point to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional id attribute.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`links`}</h2>
    <p>{`The table below contains all types of props available for links array.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name of a action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`link`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A href for linking to another page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`() => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Callback for handling action.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">{`SkipLink is visible only on focus.`}</li>
    </ul>
    <h2>{`Rationale`}</h2>
    <p>{`SkipLink is used to adress `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/navigation-mechanisms-skip.html"
      }}>{`WCAG2.0 Criterion 2.4.1`}</a>{`.
The intent of this is to allow people who navigate sequentially through content more direct access to the primary content and common actions of the Web page which can be hidden inside menus or in otherwise complex content.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
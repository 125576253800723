import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useRandomId`}</h1>
    <p><strong parentName="p">{`If you are using version 18 (or above) of React, you should use the native React.useId hook instead of this one.`}</strong>{`
The `}<inlineCode parentName="p">{`useRandomId`}</inlineCode>{` generates unique random id.`}</p>
    <p>{`In order to use the hook, you can simply import it like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useRandomId from "@kiwicom/orbit-components/lib/hooks/useRandomId"\`
`}</code></pre>
    <p>{`example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const App = () => {
  const titleId = useRandomId();

  return (
    <svg aria-labelledBy={titleId}>
      <title id={titleId}>some title</title>
      ...
    </svg>
  );
};
`}</code></pre>
    <p>{`if you need to generate multiple ids, there is a hook `}<inlineCode parentName="p">{`useRandomSeedId`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useRandomIdSeed } from "@kiwicom/orbit-components/lib/hooks/useRandomId"\`
`}</code></pre>
    <p>{`which you can use like that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const App = () => {
  const randomId = useRandomIdSeed();
  const titleId = randomId("title");
  const descriptionId = randomId("description");

  return (
    <svg aria-labelledBy={\`\${titleId} \${descriptionId}\`}>
      <title id={titleId}>some title</title>
      <desc id={descriptionId}>some description</desc>
      ...
    </svg>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import InputBackgroundLinkSnippet from "snippets/input-background-link.mdx";
import InputHelpErrorSnippet from "snippets/input-help-error.mdx";
import InputLabelsSnippet from "snippets/input-labels.mdx";
import InputPlaceholderSnippet from "snippets/input-placeholder.mdx";
import * as React from 'react';
export default {
  InputBackgroundLinkSnippet,
  InputHelpErrorSnippet,
  InputLabelsSnippet,
  InputPlaceholderSnippet,
  React
};
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Skip repeated content`}</h3>
    <p>{`Content that repeats on many screens, such as navigation,
is easy to skip when you know where it ends.
That’s clear when the content is visual.
But users need something else when they don’t have visual cues.`}</p>
    <p>{`Offer links to skip repeated content
so everyone can get to the content they’re interested in.`}</p>
    <p>{`This is enormously helpful for people who go through your content in the order it’s presented.
Without the option to skip,
they have to go through irrelevant content to get to what they want.
Skipping options lets them find content by what’s important to them,
not where it is on the page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
const useStateWithTimeout = (defaultValue, timeout) => {
  const [state, setState] = (0, _react.useState)(defaultValue);
  const timeoutRef = (0, _react.useRef)(null);
  const setStateWithTimeout = (0, _react.useCallback)(value => {
    if (typeof setTimeout === "function") {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        setState(value);
      }, timeout);
    }
  }, [timeout]);
  const clearStateTimeout = (0, _react.useCallback)(() => {
    if (timeoutRef.current !== null && typeof clearTimeout === "function") {
      clearTimeout(timeoutRef.current);
    }
  }, []);
  (0, _react.useEffect)(() => {
    return () => {
      clearStateTimeout();
    };
  }, [clearStateTimeout]);
  return [state, setState, setStateWithTimeout, clearStateTimeout];
};
var _default = exports.default = useStateWithTimeout;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Mobile vs. desktop size`}</h3>
    <p>{`While your first instinct on mobile devices might be to use smaller buttons and button links
to take up less valuable space,
this actually creates some issues.
For example, placing a small button on one side of the screen makes it much harder to access
for people using one hand (a button on the right is hard for left-handers to access).`}</p>
    <p>{`Also, without clues like hover states,
interactions in mobile devices are harder for users to guess.`}</p>
    <p>{`So on small screens, buttons and button links should take up the full width.
This makes them easy to access and hard to miss.`}</p>
    <p>{`On wider screens, users are unlikely to be using only one hand
and are used to hovering to find interactions.
So buttons and button links can fit the size of their content.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
const useIsomorphicLayoutEffect = typeof window !== "undefined" ? _react.useLayoutEffect : _react.useEffect;

// Window Event based useEventListener interface

// Element Event based useEventListener interface

// Document Event based useEventListener interface

function useEventListener(eventName, handler, element, options) {
  // Create a ref that stores handler
  const savedHandler = (0, _react.useRef)(handler);
  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  (0, _react.useEffect)(() => {
    // Define the listening target
    const targetElement = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = event => savedHandler.current(event);
    targetElement.addEventListener(eventName, eventListener, options);

    // Remove event listener on cleanup
    // eslint-disable-next-line consistent-return
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, options]);
}
var _default = exports.default = useEventListener;
"use strict";

exports.__esModule = true;
exports.weightClasses = exports.typeClasses = exports.textLinkCommonClasses = exports.sizeClasses = exports.backgroundClasses = void 0;
var _consts = require("../consts");
const typeClasses = exports.typeClasses = {
  [_consts.TYPE_OPTIONS.PRIMARY]: "text-primary-foreground [&_a:not(.orbit-text-link)]:text-link-primary-foreground hover:[&_a:not(.orbit-text-link)]:text-link-primary-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-primary-foreground-active",
  [_consts.TYPE_OPTIONS.SECONDARY]: "text-secondary-foreground [&_a:not(.orbit-text-link)]:text-link-secondary-foreground hover:[&_a:not(.orbit-text-link)]:text-link-secondary-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-secondary-foreground-active",
  [_consts.TYPE_OPTIONS.SUCCESS]: "text-success-foreground [&_a:not(.orbit-text-link)]:text-link-success-foreground hover:[&_a:not(.orbit-text-link)]:text-link-success-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-success-foreground-active",
  [_consts.TYPE_OPTIONS.INFO]: "text-info-foreground [&_a:not(.orbit-text-link)]:text-link-info-foreground hover:[&_a:not(.orbit-text-link)]:text-link-info-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-info-foreground-active",
  [_consts.TYPE_OPTIONS.WARNING]: "text-warning-foreground [&_a:not(.orbit-text-link)]:text-link-warning-foreground hover:[&_a:not(.orbit-text-link)]:text-link-warning-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-warning-foreground-active",
  [_consts.TYPE_OPTIONS.CRITICAL]: "text-critical-foreground [&_a:not(.orbit-text-link)]:text-link-critical-foreground hover:[&_a:not(.orbit-text-link)]:text-link-critical-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-critical-foreground-active",
  [_consts.TYPE_OPTIONS.WHITE]: "text-white-foreground [&_a:not(.orbit-text-link)]:text-link-white-foreground hover:[&_a:not(.orbit-text-link)]:text-link-white-foreground-hover active:[&_a:not(.orbit-text-link)]:text-link-white-foreground-active"
};
const backgroundClasses = exports.backgroundClasses = {
  [_consts.TYPE_OPTIONS.PRIMARY]: "bg-text-primary-background",
  [_consts.TYPE_OPTIONS.SECONDARY]: "bg-text-secondary-background",
  [_consts.TYPE_OPTIONS.INFO]: "bg-text-info-background",
  [_consts.TYPE_OPTIONS.SUCCESS]: "bg-text-success-background",
  [_consts.TYPE_OPTIONS.WARNING]: "bg-text-warning-background",
  [_consts.TYPE_OPTIONS.CRITICAL]: "bg-text-critical-background",
  [_consts.TYPE_OPTIONS.WHITE]: "bg-text-white-background"
};
const sizeClasses = exports.sizeClasses = {
  [_consts.SIZE_OPTIONS.SMALL]: "text-small leading-small",
  [_consts.SIZE_OPTIONS.NORMAL]: "text-normal leading-normal",
  [_consts.SIZE_OPTIONS.LARGE]: "text-large leading-large",
  [_consts.SIZE_OPTIONS.EXTRA_LARGE]: "text-extra-large leading-extra-large"
};
const weightClasses = exports.weightClasses = {
  [_consts.WEIGHT_OPTIONS.NORMAL]: "font-normal",
  [_consts.WEIGHT_OPTIONS.MEDIUM]: "font-medium",
  [_consts.WEIGHT_OPTIONS.BOLD]: "font-bold"
};
const textLinkCommonClasses = exports.textLinkCommonClasses = ["[&_a:not(.orbit-text-link)]:font-medium", "[&_a:not(.orbit-text-link)]:underline", "hover:[&_a:not(.orbit-text-link)]:no-underline", "active:[&_a:not(.orbit-text-link)]:no-underline", "hover:[&_a:not(.orbit-text-link)]:outline-none", "active:[&_a:not(.orbit-text-link)]:outline-none"];
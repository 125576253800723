"use strict";

exports.__esModule = true;
exports.default = void 0;
var _orbitDesignTokens = require("@kiwicom/orbit-design-tokens");
var _consts = require("../consts");
const getAlertButtonTypeToken = (name, type, theme) => {
  const tokens = {
    [_consts.TOKENS.backgroundButton]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.backgroundButtonInfo,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.backgroundButtonSuccess,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.backgroundButtonWarning,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.backgroundButtonCritical,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 12),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 12),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 12),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 12),
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.backgroundButtonSecondary
    },
    [_consts.TOKENS.backgroundButtonHover]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.backgroundButtonInfoHover,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.backgroundButtonSuccessHover,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.backgroundButtonWarningHover,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.backgroundButtonCriticalHover,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 18),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 18),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 18),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 18)
    },
    [_consts.TOKENS.backgroundButtonActive]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.backgroundButtonInfoActive,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.backgroundButtonSuccessActive,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.backgroundButtonWarningActive,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.backgroundButtonCriticalActive,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 24),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 24),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 24),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 24)
    },
    [_consts.TOKENS.backgroundButtonFocus]: {
      [_consts.TYPE_OPTIONS.INFO]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 10),
      [_consts.TYPE_OPTIONS.SUCCESS]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 10),
      [_consts.TYPE_OPTIONS.WARNING]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 10),
      [_consts.TYPE_OPTIONS.CRITICAL]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 10),
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueLight,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenLight,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeLight,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedLight
    },
    [_consts.TOKENS.colorTextButton]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.colorTextButtonInfo,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.colorTextButtonSuccess,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.colorTextButtonWarning,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.colorTextButtonCritical,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDark,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDark,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDark,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDark,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.colorTextButtonHover]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.colorTextButtonInfo,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.colorTextButtonSuccess,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.colorTextButtonWarning,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.colorTextButtonCritical,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDarkHover,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDarkHover,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDarkHover,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDarkHover,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.colorTextButtonActive]: {
      [_consts.TYPE_OPTIONS.INFO]: theme.orbit.colorTextButtonInfo,
      [_consts.TYPE_OPTIONS.SUCCESS]: theme.orbit.colorTextButtonSuccess,
      [_consts.TYPE_OPTIONS.WARNING]: theme.orbit.colorTextButtonWarning,
      [_consts.TYPE_OPTIONS.CRITICAL]: theme.orbit.colorTextButtonCritical,
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: theme.orbit.paletteBlueDarkActive,
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: theme.orbit.paletteGreenDarkActive,
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: theme.orbit.paletteOrangeDarkActive,
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: theme.orbit.paletteRedDarkActive,
      [_consts.TYPE_OPTIONS.SECONDARY]: theme.orbit.paletteInkDark
    },
    [_consts.TOKENS.borderColorButtonFocus]: {
      [_consts.TYPE_OPTIONS.INFO]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 50),
      [_consts.TYPE_OPTIONS.SUCCESS]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 50),
      [_consts.TYPE_OPTIONS.WARNING]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 50),
      [_consts.TYPE_OPTIONS.CRITICAL]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 50),
      [_consts.TYPE_OPTIONS.INFO_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteBlueNormal, 50),
      [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteGreenNormal, 50),
      [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteOrangeNormal, 50),
      [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: (0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteRedNormal, 50)
    }
  };
  return tokens[name][type];
};
var _default = exports.default = getAlertButtonTypeToken;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Modal`}</h1>
    <p>{`To implement Modal component into your project you’ll need to the import at least the Modal and the `}<a parentName="p" {...{
        "href": "#modalsection"
      }}>{`ModalSection`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Modal, { ModalSection } from "@kiwicom/orbit-components/lib/Modal";
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`You might need the Portal also. See it’s `}<a parentName="p" {...{
          "href": "https://orbit.kiwi/utilities/portal/"
        }}>{`docs`}</a>{`.`}</p>
    </blockquote>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Modal>
  <ModalSection>Hello World!</ModalSection>
</Modal>
`}</code></pre>
    <p>{`The Modal component has big variety of usage, please check examples for usage `}<a parentName="p" {...{
        "href": "#use-cases"
      }}>{`below`}</a>{`.`}</p>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the Modal component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the Modal. `}<a parentName="td" {...{
              "href": "#subcomponents"
            }}>{`See Subcomponents`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`lockScrolling`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether to prevent scrolling of the rest of the page while Modal is open. This is on by default to provide a better user experience.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`scrollingElementRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref (object or function)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The scrolling element, which depends on the viewport.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`Modal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fixedFooter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` the ModalFooter will be fixed to the bottom of window.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`isMobileFullPage`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` the Modal will look like a page on mobile devices.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#modal-enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum width of the Modal on desktop viewport.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClose`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onClose event. If you don’t pass any function the Close button will not be displayed and it will not be possible to close the Modal. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`preventOverlayClose`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Property for preventing closing of modal when there is a action on overlay. BEWARE: This should be used only in very specials edge-cases! It breaks user experience.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`hasCloseButton`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines whether the Modal displays a close button. If you disable this, we recommend adding some kind of an alternative.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`autoFocus`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The autofocus attribute of the Modal, see `}<a parentName="td" {...{
              "href": "https://www.w3schools.com/tags/att_autofocus.asp"
            }}>{`this docs`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`disableAnimation`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Defines whether the Modal performs the slide in animation on mobile. If you want to improve your `}<a parentName="td" {...{
              "href": "https://web.dev/cls/"
            }}>{`CLS`}</a>{` score, you might want to set this to `}<inlineCode parentName="td">{`true`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`mobileHeader`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`false`}</inlineCode>{` the ModalHeader will not have MobileHeader and CloseContainer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`labelClose`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Close`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label for the close button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onScroll`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling `}<inlineCode parentName="td">{`onScroll`}</inlineCode>{` event. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Modal enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"extraSmall"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"extraLarge"`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Functional specs`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To select the Close Button element for testing purposes, use `}{`[data-test=“ModalCloseButton”]`}{` selector.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To type a reference you’re passing to a modal, use the following example:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`const modalRef = React.useRef<React.ElementRef<typeof Modal> | null>(null)
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You might want to get the current scroll position of a Modal component, which might change based on media queries. Reading it constantly would degrade performance. Instead, get it on demand by using the `}<inlineCode parentName="p">{`getScrollPosition`}</inlineCode>{` method in a Modal instance like this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`class Component extends React.Component {
  const modalRef = React.useRef<React.ElementRef<typeof Modal> | null>(null)

  const getScroll = () => {
    if (modalRef.current) {
      setLocalScrollPosition(modalRef.current.getScrollPosition());
    }
  };

  render() {
    return (
      <Modal ref={modalRef}>
        Some content.
      </Modal>
    );
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To set the scroll position of a Modal component, use the `}<inlineCode parentName="p">{`setScrollPosition`}</inlineCode>{` method in a Modal instance like this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`class Component extends React.Component {
  const modalRef = React.useRef<React.ElementRef<typeof Modal> | null>(null)

  setScroll = () => {
    if (modalRef.current) {
      modalRef.current.setScrollPosition(100);
    }
  };
  render() {
    return (
      <Modal ref={modalRef}>
        <ModalSection>Example usage of setting up the scrollTop position</ModalSection>
        <ModalFooter>
          <Button onClick={this.setScroll}>Change scrollTop</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
`}</code></pre>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Subcomponents`}</h2>
    <p>{`Modal component offers a good flexibility and many variations in its usage. There are three subcomponents which you might use.`}</p>
    <h3>{`ModalSection`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Modal, { ModalSection } from "@kiwicom/orbit-components/lib/Modal";
`}</code></pre>
    <h4>{`Usage`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Modal>
  <ModalSection suppressed>Hello World!</ModalSection>
</Modal>
`}</code></pre>
    <h4>{`Props`}</h4>
    <p>{`Table below contains all types of the props in the ModalSection component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Content of the ModalSection component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`suppressed`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` the ModalSection will have cloudy background.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`ModalHeader`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Modal, { ModalHeader } from "@kiwicom/orbit-components/lib/Modal";
`}</code></pre>
    <h4>{`Usage`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Modal>
  <ModalHeader title="Orbit design system">Hello World!</ModalHeader>
</Modal>
`}</code></pre>
    <h4>{`Props`}</h4>
    <p>{`Table below contains all types of the props in the ModalHeader component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the ModalHeader.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The displayed description of the ModalHeader.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`illustration`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Element<typeof Illustration>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The displayed Illustration of the ModalHeader.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`suppressed`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If `}<inlineCode parentName="td">{`true`}</inlineCode>{` the ModalHeader will have cloudy background.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The displayed title of the ModalHeader.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`ModalFooter`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Modal, { ModalFooter } from "@kiwicom/orbit-components/lib/Modal";

// and probably Button
import Button from "@kiwicom/orbit-components/lib/Button";
`}</code></pre>
    <h4>{`Usage:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Modal fixedFooter>
  <ModalFooter flex={["0 0 auto", "1 1 100%"]}>
    <Button type="secondary" iconLeft={<ChevronBackward />}>
      Back
    </Button>
    <Button block>Continue to Payment</Button>
  </ModalFooter>
</Modal>
`}</code></pre>
    <h4>{`Props`}</h4>
    <p>{`Table below contains all types of the props in the ModalFooter component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the ModalFooter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`flex`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` or `}<inlineCode parentName="td">{`Array<string>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"0 1 auto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The flex attribute(s) for children of the ModalFooter. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
      </tbody>
    </table>
    <h4>{`ModalFooter Functional specs`}</h4>
    <ul>
      <li parentName="ul">{`You can set up different `}<inlineCode parentName="li">{`flex`}</inlineCode>{` attribute for every children, or use one for all. See `}<a parentName="li" {...{
          "href": "https://www.w3schools.com/cssref/css3_pr_flex.asp"
        }}>{`flex property docs`}</a>{` for more information.`}</li>
    </ul>
    <h2>{`Use cases`}</h2>
    <p>{`Although this component offers good flexibility of usage, there are tiny limitations for usage.`}</p>
    <h3>{`Wrapper ModalSections`}</h3>
    <p>{`If you need to wrap the children into custom component, wrap all of the children into `}<strong parentName="p">{`one wrapper`}</strong>{`, e.g.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// good
<Modal fixedFooter>
  <CustomWrapper>
    <ModalHeader />
      <ModalSection>
        My content
      </ModalSection>
      <ModalSection>
        My content
      </ModalSection>
    <ModalFooter />
  </CustomWrapper>
</Modal>

// bad, the CSS styles will be broken
<Modal fixedFooter>
  <ModalHeader />
  <CustomWrapper>
    <ModalSection>
      My content
    </ModalSection>
    <ModalSection>
      My content
    </ModalSection>
  </CustomWrapper>
  <ModalFooter />
</Modal>
`}</code></pre>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`When Modal is closed return focus to the element that opened the modal. You can use `}<inlineCode parentName="li">{`onClose`}</inlineCode>{` callback function to achieve this.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
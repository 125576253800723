import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Don’t hide anything essential`}</h3>
    <p>{`Even with differently sized screens, users still have the same basic needs.
Make sure that even if you hide things, users can still accomplish their main tasks.`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "/design-patterns/progressive-disclosure/"
      }}>{`progressive disclosure`}</a>{`
to keep content available but initially out of sight.
In this way, you hide content on specific screens but offer clear alternatives to get to it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
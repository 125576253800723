"use strict";

exports.__esModule = true;
exports.default = void 0;
var _rtl = require("../../../utils/rtl");
var _consts = require("./consts");
const getSpacing = (onlyIcon, iconRight, iconLeft, size, theme) => {
  const wrappedRtl = value => (0, _rtl.rtlSpacing)(value)({
    theme
  });
  if (onlyIcon) return wrappedRtl(theme.orbit.paddingButtonWithoutText);
  const tokens = {
    [_consts.TOKENS.paddingButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.paddingButtonLarge,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.paddingButtonNormal,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.paddingButtonSmall
    },
    [_consts.TOKENS.paddingButtonWithIcons]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.paddingButtonLargeWithIcons,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.paddingButtonNormalWithIcons,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.paddingButtonSmallWithIcons
    },
    [_consts.TOKENS.paddingButtonWithLeftIcon]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.paddingButtonLargeWithLeftIcon,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.paddingButtonNormalWithLeftIcon,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.paddingButtonSmallWithLeftIcon
    },
    [_consts.TOKENS.paddingButtonWithRightIcon]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.paddingButtonLargeWithRightIcon,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.paddingButtonNormalWithRightIcon,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.paddingButtonSmallWithRightIcon
    }
  };
  if (iconLeft && iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithIcons][size]);
  if (iconLeft && !iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithLeftIcon][size]);
  if (!iconLeft && iconRight) return wrappedRtl(tokens[_consts.TOKENS.paddingButtonWithRightIcon][size]);
  return wrappedRtl(tokens[_consts.TOKENS.paddingButton][size]);
};
var _default = exports.default = getSpacing;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useFocusTrap`}</h1>
    <p>{`To implement the `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` hook in your component, add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useFocusTrap from "@kiwicom/orbit-components/lib/hooks/useFocusTrap";
`}</code></pre>
    <p>{`Then you can use it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Component = (props: Props) => {
  const ref = React.useRef(null);
  useFocusTrap(ref);

  return <OtherComponent ref={ref}>...etc</OtherComponent>;
};
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all parameters available to the `}<inlineCode parentName="p">{`useFocusTrap`}</inlineCode>{` hook.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Component reference`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.spaceAfterClasses = exports.default = void 0;
var _mediaQuery = require("../../utils/mediaQuery");
var _consts = require("../consts");
const spaceAfterClasses = exports.spaceAfterClasses = {
  [_consts.SPACINGS_AFTER.NONE]: "mb-0",
  [_consts.SPACINGS_AFTER.SMALLEST]: "mb-100",
  [_consts.SPACINGS_AFTER.SMALL]: "mb-200",
  [_consts.SPACINGS_AFTER.NORMAL]: "mb-300",
  [_consts.SPACINGS_AFTER.MEDIUM]: "mb-400",
  [_consts.SPACINGS_AFTER.LARGE]: "mb-600",
  [_consts.SPACINGS_AFTER.LARGEST]: "mb-800",
  [_mediaQuery.QUERIES.MEDIUMMOBILE]: {
    [_consts.SPACINGS_AFTER.NONE]: "mm:mb-0",
    [_consts.SPACINGS_AFTER.SMALLEST]: "mm:mb-100",
    [_consts.SPACINGS_AFTER.SMALL]: "mm:mb-200",
    [_consts.SPACINGS_AFTER.NORMAL]: "mm:mb-300",
    [_consts.SPACINGS_AFTER.MEDIUM]: "mm:mb-400",
    [_consts.SPACINGS_AFTER.LARGE]: "mm:mb-600",
    [_consts.SPACINGS_AFTER.LARGEST]: "mm:mb-800"
  },
  [_mediaQuery.QUERIES.LARGEMOBILE]: {
    [_consts.SPACINGS_AFTER.NONE]: "lm:mb-0",
    [_consts.SPACINGS_AFTER.SMALLEST]: "lm:mb-100",
    [_consts.SPACINGS_AFTER.SMALL]: "lm:mb-200",
    [_consts.SPACINGS_AFTER.NORMAL]: "lm:mb-300",
    [_consts.SPACINGS_AFTER.MEDIUM]: "lm:mb-400",
    [_consts.SPACINGS_AFTER.LARGE]: "lm:mb-600",
    [_consts.SPACINGS_AFTER.LARGEST]: "lm:mb-800"
  },
  [_mediaQuery.QUERIES.TABLET]: {
    [_consts.SPACINGS_AFTER.NONE]: "tb:mb-0",
    [_consts.SPACINGS_AFTER.SMALLEST]: "tb:mb-100",
    [_consts.SPACINGS_AFTER.SMALL]: "tb:mb-200",
    [_consts.SPACINGS_AFTER.NORMAL]: "tb:mb-300",
    [_consts.SPACINGS_AFTER.MEDIUM]: "tb:mb-400",
    [_consts.SPACINGS_AFTER.LARGE]: "tb:mb-600",
    [_consts.SPACINGS_AFTER.LARGEST]: "tb:mb-800"
  },
  [_mediaQuery.QUERIES.DESKTOP]: {
    [_consts.SPACINGS_AFTER.NONE]: "de:mb-0",
    [_consts.SPACINGS_AFTER.SMALLEST]: "de:mb-100",
    [_consts.SPACINGS_AFTER.SMALL]: "de:mb-200",
    [_consts.SPACINGS_AFTER.NORMAL]: "de:mb-300",
    [_consts.SPACINGS_AFTER.MEDIUM]: "de:mb-400",
    [_consts.SPACINGS_AFTER.LARGE]: "de:mb-600",
    [_consts.SPACINGS_AFTER.LARGEST]: "de:mb-800"
  },
  [_mediaQuery.QUERIES.LARGEDESKTOP]: {
    [_consts.SPACINGS_AFTER.NONE]: "ld:mb-0",
    [_consts.SPACINGS_AFTER.SMALLEST]: "ld:mb-100",
    [_consts.SPACINGS_AFTER.SMALL]: "ld:mb-200",
    [_consts.SPACINGS_AFTER.NORMAL]: "ld:mb-300",
    [_consts.SPACINGS_AFTER.MEDIUM]: "ld:mb-400",
    [_consts.SPACINGS_AFTER.LARGE]: "ld:mb-600",
    [_consts.SPACINGS_AFTER.LARGEST]: "ld:mb-800"
  }
};
const getSpaceAfterClasses = (spaceAfter, viewport) => {
  return viewport ? spaceAfterClasses[viewport][spaceAfter] : spaceAfterClasses[spaceAfter];
};
var _default = exports.default = getSpaceAfterClasses;
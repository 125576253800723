"use strict";

exports.__esModule = true;
exports.default = void 0;
var _consts = require("./consts");
var _rtl = require("../../../utils/rtl");
var _Icon = require("../../../Icon");
var _consts2 = require("../../../Icon/consts");
const getIconSpacing = (onlyIcon, theme) => {
  if (onlyIcon) return null;
  return {
    leftMargin: (0, _rtl.rtlSpacing)(`0 ${theme.orbit.marginButtonIcon} 0 0`)({
      theme
    }),
    rightMargin: (0, _rtl.rtlSpacing)(`0 0 0 ${theme.orbit.marginButtonIcon}`)({
      theme
    })
  };
};
const getIconSize = size => {
  if (size === _consts.SIZE_OPTIONS.SMALL) return _consts2.ICON_SIZES.SMALL;
  if (size === _consts.SIZE_OPTIONS.LARGE) return _consts2.ICON_SIZES.LARGE;
  return _consts2.ICON_SIZES.MEDIUM;
};
const getIconContainer = ({
  iconLeft,
  children,
  theme,
  size = _consts.SIZE_OPTIONS.NORMAL,
  iconForeground
}) => {
  const sizeIcon = getIconSize(size);
  const computedSize = (0, _Icon.getSize)(sizeIcon)({
    theme
  });
  const onlyIcon = Boolean(iconLeft && !children);
  return {
    icons: {
      height: computedSize,
      width: computedSize,
      ...getIconSpacing(onlyIcon, theme),
      ...iconForeground
    }
  };
};
var _default = exports.default = getIconContainer;
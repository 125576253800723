"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _useEventListener = _interopRequireDefault(require("../useEventListener"));
const useClickOutside = (ref, handler, mouseEvent = "mousedown") => {
  (0, _useEventListener.default)(mouseEvent, event => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    if (!el || el.contains(event.target)) {
      return;
    }
    handler(event);
  });
};
var _default = exports.default = useClickOutside;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard").default;
exports.__esModule = true;
exports.default = void 0;
var React = _interopRequireWildcard(require("react"));
var _useTheme = _interopRequireDefault(require("../useTheme"));
var _mediaQuery = require("../../utils/mediaQuery");
var _QueryContext = _interopRequireWildcard(require("../../OrbitProvider/QueryContext"));
const QUERIES = ["isMediumMobile", "isLargeMobile", "isTablet", "isDesktop", "isLargeDesktop", "prefersReducedMotion"];
const useMediaQuery = () => {
  const theme = (0, _useTheme.default)();
  const contextValue = React.useContext(_QueryContext.default);
  const [result, setResult] = React.useState(contextValue);

  // if context value is anything other than initial, it's available
  const hasContext = QUERIES.some(query => contextValue[query] !== _QueryContext.initialValue[query]);
  React.useEffect(() => {
    let mqListMap;
    const listenerMap = {};

    // if context is available, skip computing
    if (!hasContext && typeof window !== "undefined" && typeof window.matchMedia === "function") {
      const createMqList = (query, mediaQuery) => {
        const mqList = window.matchMedia(mediaQuery);
        listenerMap[query] = ({
          matches
        }) => {
          setResult(prev => {
            const next = {
              ...prev
            };
            next[query] = matches;
            return next;
          });
        };
        mqList.addEventListener("change", listenerMap[query]);
        return mqList;
      };
      mqListMap = {
        isMediumMobile: createMqList("isMediumMobile", (0, _mediaQuery.getBreakpointWidth)("mediumMobile", theme)),
        isLargeMobile: createMqList("isLargeMobile", (0, _mediaQuery.getBreakpointWidth)("largeMobile", theme)),
        isTablet: createMqList("isTablet", (0, _mediaQuery.getBreakpointWidth)("tablet", theme)),
        isDesktop: createMqList("isDesktop", (0, _mediaQuery.getBreakpointWidth)("desktop", theme)),
        isLargeDesktop: createMqList("isLargeDesktop", (0, _mediaQuery.getBreakpointWidth)("largeDesktop", theme)),
        prefersReducedMotion: createMqList("prefersReducedMotion", "(prefers-reduced-motion: reduce)")
      };

      // initialize
      setResult(prev => {
        const next = {
          ...prev
        };
        QUERIES.forEach(query => {
          next[query] = mqListMap[query].matches;
        });
        return QUERIES.every(query => next[query] === prev[query]) ? prev : next;
      });
    }
    return () => {
      if (mqListMap) {
        QUERIES.forEach(query => {
          mqListMap[query].removeEventListener("change", listenerMap[query]);
        });
      }
    };
  }, [theme, hasContext]);

  // forward context if it's available
  return hasContext ? contextValue : result;
};
var _default = exports.default = useMediaQuery;
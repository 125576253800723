"use strict";

exports.__esModule = true;
exports.default = void 0;
const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  HOME: 36,
  END: 35,
  ESC: 27
};
var _default = exports.default = KEY_CODE;
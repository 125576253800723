import { Grid } from "@kiwicom/orbit-components";
import AlertWebImage from "../../../../src/images/component-structure/web/alert.svg";
import AlertInfoActions from "../../../../src/images/alert-info-w-actions.svg";
import AlertUseIconsDo from "../../../../src/images/alert-use-icons_do.svg";
import AlertUseIconsDont from "../../../../src/images/alert-use-icons_dont.svg";
import * as React from 'react';
export default {
  Grid,
  AlertWebImage,
  AlertInfoActions,
  AlertUseIconsDo,
  AlertUseIconsDont,
  React
};
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _getWrap = _interopRequireDefault(require("./getWrap"));
var _getPosition = _interopRequireDefault(require("./getPosition"));
var _getTextAlign = _interopRequireDefault(require("./getTextAlign"));
var _getElevation = _interopRequireDefault(require("./getElevation"));
var _getBorderRadius = _interopRequireDefault(require("./getBorderRadius"));
var _getOverflow = _interopRequireDefault(require("./getOverflow"));
var _getColor = _interopRequireDefault(require("./getColor"));
var _getBackground = _interopRequireDefault(require("./getBackground"));
var _getPadding = _interopRequireDefault(require("./getPadding"));
var _getMargin = _interopRequireDefault(require("./getMargin"));
var _tailwind = require("../../common/tailwind");
const getTailwindClasses = (props, viewport) => {
  const {
    display,
    wrap,
    direction,
    position,
    align,
    justify,
    textAlign,
    elevation,
    borderRadius,
    overflow,
    color,
    background,
    padding,
    margin
  } = props;
  return [display && (0, _tailwind.getDisplayClasses)(display, viewport), wrap && (0, _getWrap.default)(wrap, viewport), direction && (0, _tailwind.getDirectionClasses)(direction, viewport), position && (0, _getPosition.default)(position, viewport), align && (0, _tailwind.getAlignItemsClasses)(align, viewport), justify && (0, _tailwind.getJustifyClasses)(justify, viewport), textAlign && (0, _getTextAlign.default)(textAlign, viewport), elevation && (0, _getElevation.default)(elevation, viewport), borderRadius && (0, _getBorderRadius.default)(borderRadius, viewport), overflow && (0, _getOverflow.default)(overflow, viewport), color && (0, _getColor.default)(color, viewport), background && (0, _getBackground.default)(background, viewport), padding && (0, _getPadding.default)(padding, viewport), margin && (0, _getMargin.default)(margin, viewport)];
};
var _default = exports.default = getTailwindClasses;
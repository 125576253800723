export default function mergeRefs(refs) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null && typeof ref !== "string") {
        // @ts-expect-error expected to be mutable
        // eslint-disable-next-line no-param-reassign
        ref.current = value;
      }
    });
  };
}
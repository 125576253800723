import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Layout`}</h1>
    <p>{`To implement the Layout component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Layout type="MMB">
  <LayoutColumn>This is the main section.</LayoutColumn>
</Layout>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the Layout component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The children of the Layout, use `}<a parentName="td" {...{
              "href": "#layoutcolumn"
            }}><inlineCode parentName="a">{`LayoutColumn`}</inlineCode></a>{` as top-wrapper.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`type`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#layouts"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The type of the Layout.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`LayoutColumn`}</h3>
    <p>{`LayoutColumn component gives us a possibility how to control all accessibility and behaviour properties globally, so you don’t have to worry about it.
`}<strong parentName="p">{`If you want to use `}<inlineCode parentName="strong">{`Layout`}</inlineCode>{` component in your project, use `}<inlineCode parentName="strong">{`LayoutColumn`}</inlineCode>{` also.`}</strong></p>
    <p>{`Table below contains all types of the props available in the LayoutColumn component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The HTML element in which item will be rendered`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The children of the LayoutColumn.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Layouts`}</h2>
    <p>{`For now, we are providing three global layouts that are necessary for our projects.
There is possibility to use `}<inlineCode parentName="p">{`Search`}</inlineCode>{`, `}<inlineCode parentName="p">{`Booking`}</inlineCode>{` or `}<inlineCode parentName="p">{`MMB`}</inlineCode>{` layout.`}</p>
    <h3>{`Search`}</h3>
    <p>{`The Search layout consist of three columns.`}</p>
    <p>{`To implement the Search layout into your project, you need to use this JSX markup:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";

const App = () => (
  <Layout type="Search">
    <LayoutColumn>The left SideBar for filters</LayoutColumn>
    <LayoutColumn>The main section for displaying the results</LayoutColumn>
    <LayoutColumn>The right SideBar for promotion</LayoutColumn>
  </Layout>
);
`}</code></pre>
    <h3>{`Booking`}</h3>
    <p>{`The Booking layout consist of two columns.`}</p>
    <p>{`To implement the Booking layout into your project, you need to use this JSX markup:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";

const App = () => (
  <Layout type="Booking">
    <LayoutColumn>The main section for Booking form</LayoutColumn>
    <LayoutColumn>The left SideBar for displaying the summary</LayoutColumn>
  </Layout>
);
`}</code></pre>
    <h3>{`MMB`}</h3>
    <p>{`The ManageMyBooking layout consist only of one column.`}</p>
    <p>{`To implement the MMB layout into your project, you need to use this JSX markup:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout, { LayoutColumn } from "@kiwicom/orbit-components/lib/Layout";

const App = () => (
  <Layout type="MMB">
    <LayoutColumn>The main section for ManageMyBooking</LayoutColumn>
  </Layout>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.useRandomIdSeed = exports.useRandomId = exports.default = void 0;
var _Provider = require("../../OrbitProvider/RandomId/Provider");
const useRandomId = () => {
  const useId = (0, _Provider.useRandomId)();
  return useId();
};
exports.useRandomId = useRandomId;
const useRandomIdSeed = () => {
  const useId = (0, _Provider.useRandomId)();
  const prefix = useId();
  return seed => `${prefix}-${seed}`;
};
exports.useRandomIdSeed = useRandomIdSeed;
var _default = exports.default = useRandomId;
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _react = require("react");
var _ModalContext = require("../ModalContext");
const useModalContextFunctions = () => {
  const {
    callContextFunctions
  } = (0, _react.useContext)(_ModalContext.ModalContext);
  (0, _react.useEffect)(() => {
    if (callContextFunctions) callContextFunctions();
  }, [callContextFunctions]);
};
var _default = exports.default = useModalContextFunctions;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _keyMaps = _interopRequireDefault(require("../../common/keyMaps"));
const handleKeyDown = (onAction, action) => ev => {
  if (ev.keyCode === _keyMaps.default.ENTER) {
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  } else if (ev.keyCode === _keyMaps.default.SPACE) {
    ev.preventDefault();
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  }
};
var _default = exports.default = handleKeyDown;
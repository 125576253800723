import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`useIntersect`}</h1>
    <p>{`To implement the `}<inlineCode parentName="p">{`useIntersect`}</inlineCode>{` hook in your component, add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import useIntersect from "@kiwicom/orbit-components/lib/hooks/useIntersect";
`}</code></pre>
    <p>{`Then you can use it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const LazyLoaded = ({ alt, src, placeholder, ...props }: Props) => {
  const [source, setSource] = React.useState(placeholder);
  const [loaded, setLoaded] = React.useState(false);

  const { ref, entry, observer } = useIntersect({
    threshold: 0.01,
    rootMargin: "150px",
  });

  React.useEffect(() => {
    if (entry?.isIntersecting) {
      setSource(src);
      setLoaded(true);
    }
  }, [src, entry]);

  return <StyledImage alt={alt} blur={!loaded} src={source} ref={ref} {...props} />;
};
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all parameters available to the `}<inlineCode parentName="p">{`useIntersect`}</inlineCode>{` hook.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Options`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#Options"
            }}><inlineCode parentName="a">{`Options`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`IntersectionObserver options`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Options`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`root`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`null \\| HTMLElement`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`threshold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`rootMargin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Examples of usage`}</h3>
    <ul>
      <li parentName="ul">{`Lazy-loading of images or other content as a page is scrolled.`}</li>
      <li parentName="ul">{`Implementing “infinite scrolling” web sites, where more and more content is loaded and rendered as you scroll, so that the user doesn’t have to flip through pages.`}</li>
      <li parentName="ul">{`Reporting of visibility of advertisements in order to calculate ad revenues.`}</li>
      <li parentName="ul">{`Deciding whether or not to perform tasks or animation processes based on whether or not the user will see the result.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _orbitDesignTokens = require("@kiwicom/orbit-design-tokens");
const defaultTheme = {
  orbit: _orbitDesignTokens.defaultTokens,
  transitions: true,
  lockScrolling: true,
  lockScrollingBarGap: false,
  rtl: false
};
var _default = exports.default = defaultTheme;
import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Palette from "../components/Palette";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`You can usually have icons inherit their color from the surrounding text.
If you need a standalone icon, select from a `}<a parentName="p" {...{
        "href": "/foundation/color/#status-colors"
      }}>{`status color`}</a>{`
or one of our icon colors:`}</p>
    <Palette colors={["colorIconPrimary", "colorIconSecondary", "colorIconTertiary"]} mdxType="Palette" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
exports.__esModule = true;
exports.default = void 0;
var _orbitDesignTokens = require("@kiwicom/orbit-design-tokens");
var _getAlertButtonTypeToken = _interopRequireDefault(require("./getAlertButtonTypeToken"));
var _consts = require("../consts");
var _consts2 = require("../../../primitives/ButtonPrimitive/common/consts");
const opacity = {
  [_consts.TYPE_OPTIONS.INFO]: 15,
  [_consts.TYPE_OPTIONS.SUCCESS]: 15,
  [_consts.TYPE_OPTIONS.WARNING]: 15,
  [_consts.TYPE_OPTIONS.CRITICAL]: 15,
  [_consts.TYPE_OPTIONS.INFO_SUBTLE]: 8,
  [_consts.TYPE_OPTIONS.SUCCESS_SUBTLE]: 8,
  [_consts.TYPE_OPTIONS.WARNING_SUBTLE]: 8,
  [_consts.TYPE_OPTIONS.CRITICAL_SUBTLE]: 8
};
const getAlertButtonBoxShadow = (state, disabled, theme, type) => {
  const wrappedButtonTypeToken = name => (0, _getAlertButtonTypeToken.default)(name, type, theme);
  if (disabled) return null;
  if (state === _consts2.BUTTON_STATES.ACTIVE) {
    return `inset 0 0 6px 3px ${(0, _orbitDesignTokens.convertHexToRgba)(theme.orbit.paletteInkDark, opacity[type])};`;
  }
  if (state === _consts2.BUTTON_STATES.FOCUS) {
    return `0 0 0 3px ${wrappedButtonTypeToken(_consts.TOKENS.borderColorButtonFocus)}`;
  }
  return null;
};
var _default = exports.default = getAlertButtonBoxShadow;
import KEY_CODE_MAP from "../../common/keyMaps";
const handleKeyDown = (onAction, action) => ev => {
  if (ev.keyCode === KEY_CODE_MAP.ENTER) {
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  } else if (ev.keyCode === KEY_CODE_MAP.SPACE) {
    ev.preventDefault();
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  }
};
export default handleKeyDown;
"use strict";

exports.__esModule = true;
exports.default = void 0;
var _consts = require("./consts");
const getSizeToken = (size, theme) => {
  const tokens = {
    [_consts.TOKENS.heightButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.heightButtonLarge,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.heightButtonNormal,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.heightButtonSmall
    },
    [_consts.TOKENS.fontSizeButton]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.fontSizeButtonLarge,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.fontSizeButtonNormal,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.fontSizeButtonSmall
    },
    [_consts.TOKENS.spinnerWidth]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.widthIconMedium,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.widthIconMedium,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.widthIconSmall
    },
    [_consts.TOKENS.spinnerHeight]: {
      [_consts.SIZE_OPTIONS.LARGE]: theme.orbit.heightIconMedium,
      [_consts.SIZE_OPTIONS.NORMAL]: theme.orbit.heightIconMedium,
      [_consts.SIZE_OPTIONS.SMALL]: theme.orbit.heightIconSmall
    }
  };
  return {
    height: tokens[_consts.TOKENS.heightButton][size],
    fontSize: tokens[_consts.TOKENS.fontSizeButton][size]
  };
};
var _default = exports.default = getSizeToken;